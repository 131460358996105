import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Box,
} from "@mui/material";
// App
import { accountService, alertService } from "../../services";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string("Enter your password")
      .min(8, "Password should be of minimum 8 characters length")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: validationSchema,

    onSubmit: ({ email, password }, { setSubmitting }) => {
      accountService
        .login(email, password)
        .then(() => {
          const { from } = location.state || { from: { pathname: "/" } };
          navigate(from);
        })
        .catch((error) => {
          setSubmitting(false);
          alertService.error(error);
        });
    },
  });

  React.useEffect(() => {
    // redirect to home if already logged in
    if (accountService.userValue) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} sx={{ width: 600, m: "20px auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Sign In</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" spacing={8}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
                <Box variant="h6" component="div" sx={{ flexGrow: 1 }}></Box>
                <NavLink to="/account/forgot-password">
                  Forgot Password?
                </NavLink>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </div>
  );
}

export default Login;
