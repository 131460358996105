// const url = "http://127.0.0.1:8000";
const API_AI_URL = "https://aipfrc-api.herokuapp.com";

export const friService = {
  post,
};

function post(body) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    // credentials: "include",
    body: JSON.stringify(body),
  };

  return fetch(API_AI_URL + "/frc/fri", requestOptions).then(handleResponse);
}

// Utilities
//-----------------------------------------------------------
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403, 404].includes(response.status)) {
        console.log(response.status);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
