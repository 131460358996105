import React from "react";
import { Grid, Typography, Paper, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  background: theme.palette.primary,
  padding: theme.spacing(2),
}));

const Section = (props) => {
  return (
    <Item elevation={0} variant="outlined">
      <Grid container spacing={2} justifyContent="center">
        <Grid item sm={12}>
          <Typography variant="h4" align={props.align}>
            {props.title}
          </Typography>
        </Grid>
        <Grid item sm={12}>
          {props.children}
        </Grid>
        <Grid item sm={12}></Grid>
      </Grid>
    </Item>
  );
};

export default Section;
