import * as React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// App
import { accountService, frcService } from "../../services";
import useAlert from "../../hooks/useAlert";
import ConfirmDialog from "../../mui/ConfirmDialog";

const columns = [
  {
    field: "user",
    headerName: "User",
    width: 400,
  },
  {
    field: "reference",
    headerName: "Reference",
    width: 400,
  },
  {
    field: "specimen",
    headerName: "Specimen",
    width: 200,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

export default function DataTable() {
  const navigate = useNavigate();
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { Alert, setAlert } = useAlert();

  const handleEdit = () => {
    if (selected.length === 0) {
      setAlert({
        open: true,
        type: "error",
        message: `Please select a row first`,
      });
      return;
    }

    if (selected.length !== 1) {
      setAlert({
        open: true,
        type: "error",
        message: `Please select only one row`,
      });
      return;
    }

    navigate(`/edit/${rows[selected]._id}`);
  };

  const handleDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    selected.map((e) =>
      frcService
        .remove(rows[e]._id)
        .then(() => setRows((rows) => rows.filter((x) => x.id !== e)))
    );

    setSelected([]);
  };

  React.useEffect(() => {
    const user = accountService.userValue;
    const getRecords = async () => {
      const records = [];
      const data = await frcService.getAll();
      if (data) {
        data.map((e, index) => {
          return records.push({
            _id: e._id,
            id: index,
            user: e.user,
            reference: e.reference,
            specimen: e.specimen,
          });
        });
      }
      setRows(records);
    };
    getRecords();
  }, []);

  const tableToolbar = () => {
    return (
      <Toolbar>
        <Stack direction="row">
          <Tooltip title="Edit selected data">
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete selected data">
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Toolbar>
    );
  };

  return (
    <div>
      {tableToolbar()}
      <div style={{ height: 1000, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          onPageSizeChange={(e) => setRowsPerPage(e)}
          rowsPerPageOptions={[5, 25, 50, 100]}
          components={{ Toolbar: CustomToolbar }}
          onSelectionModelChange={(row) => setSelected(row)}
          sx={{ mb: 0 }}
        />
      </div>

      <Alert />

      <ConfirmDialog
        title="Delete"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      >
        Are you sure you want to delete the selected data?
      </ConfirmDialog>
    </div>
  );
}
