import * as React from "react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
// App
import { accountService, frcService } from "../../services";
import useAlert from "../../hooks/useAlert";
import ConfirmDialog from "../../mui/ConfirmDialog";

const columns = [
  {
    field: "title",
    headerName: "Title",
    width: 80,
  },
  {
    field: "firstName",
    headerName: "First Name",
    width: 150,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 150,
  },
  {
    field: "affiliation",
    headerName: "Affiliation",
    width: 250,
  },
  {
    field: "country",
    headerName: "Country",
    width: 150,
  },
  {
    field: "position",
    headerName: "Position",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function UsersTable() {
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { Alert, setAlert } = useAlert();

  const handleDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    selected.map((e) =>
      frcService
        .remove(rows[e]._id)
        .then(() => setRows((rows) => rows.filter((x) => x.id !== e)))
    );

    setSelected([]);
  };

  React.useEffect(() => {
    countries.registerLocale(enLocale);

    const getRecords = async () => {
      const records = [];
      const data = await accountService.getAll();
      if (data) {
        data.map((e, index) => {
          return records.push({
            _id: e._id,
            id: index,
            title: e.title,
            firstName: e.firstName,
            lastName: e.lastName,
            affiliation: e.affiliation,
            country: countries.getName(e.country, "en"),
            position: e.position,
            email: e.email,
          });
        });
      }
      setRows(records);
    };
    getRecords();
  }, []);

  const tableToolbar = () => {
    return (
      <Toolbar>
        <Stack direction="row">
          <Tooltip title="Delete selected data">
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Toolbar>
    );
  };

  return (
    <div>
      {tableToolbar()}
      <div style={{ height: 1000, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          onPageSizeChange={(e) => setRowsPerPage(e)}
          rowsPerPageOptions={[5, 25, 50, 100]}
          components={{ Toolbar: CustomToolbar }}
          onSelectionModelChange={(row) => setSelected(row)}
        />
      </div>

      <Alert />

      <ConfirmDialog
        title="Delete"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      >
        Are you sure you want to delete the selected data?
      </ConfirmDialog>
    </div>
  );
}
