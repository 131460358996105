import * as React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Stack,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import HomeIcon from "@mui/icons-material/Home";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import ColorModeContext from "../contexts/ColorModeContext";
import { accountService } from "../services";

function Nav() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [user, setUser] = React.useState(null);

  const handleLogoout = () => {
    accountService.logout();
    navigate("/");
  };

  React.useEffect(() => {
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;
  }, []);

  const navAdmin = () => {
    return (
      <div>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate("/admin-data");
          }}
        >
          Manage data
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            navigate("/admin-users");
          }}
        >
          Manage users
        </MenuItem>
        <Divider />
      </div>
    );
  };

  const navUser = () => {
    const user = accountService.userValue;
    const isAdmin = user.role === "Admin" ? true : false;

    return (
      <div>
        <IconButton size="small">
          <Link to="/add" style={{ color: "white", textDecoration: "none" }}>
            <AddIcon />
          </Link>
        </IconButton>

        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => {}}>
            Signed in as {user.firstName} {user.lastName}
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/account/register/" + user.id);
            }}
          >
            Your profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/user-data");
            }}
          >
            Your data
          </MenuItem>
          <Divider />
          {isAdmin && navAdmin()}
          <MenuItem onClick={handleLogoout}>Sign out</MenuItem>
        </Menu>
      </div>
    );
  };

  const visitor = () => {
    return (
      <Stack direction="row" spacing={1}>
        <Button color="inherit" variant="text">
          <NavLink
            to="account/login"
            style={{ color: "white", textDecoration: "none" }}
          >
            Sign In
          </NavLink>
        </Button>

        <Button color="inherit" variant="outlined">
          <NavLink
            to="account/register"
            style={{ color: "white", textDecoration: "none" }}
          >
            Sign Up
          </NavLink>
        </Button>
      </Stack>
    );
  };

  return (
    <AppBar position="static" elevation={1}>
      <Toolbar>
        <IconButton size="small" color="inherit">
          <NavLink to="/" style={{ color: "white", textDecoration: "none" }}>
            <HomeIcon />
          </NavLink>
        </IconButton>{" "}
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        <IconButton
          size="small"
          color="inherit"
          onClick={colorMode.toggleColorMode}
        >
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
        {!user && visitor()}
        {accountService.userValue && navUser()}
      </Toolbar>
    </AppBar>
  );
}

export { Nav };
