import { frcService } from "./frc.service";

import { min, max, mean, quantileSeq, std } from "mathjs";

export const statsService = {
  getStats,
  getTotalData,
  getPieData,
};

/**
 * Gets the total number of records in the database.
 * @returns The total number of records.
 */
async function getTotalData() {
  const data = await frcService.getAll();
  return data.length;
}

/**
 * Gets the statistics of a giver field.
 * @param {*} field - database field name.
 * @returns An array containing the values of the field and an object with
 * the statistics.
 */
async function getStats(field) {
  const data = await frcService.getValues(field);
  const arr = data.map(Number);

  return {
    values: arr,
    stats: {
      total: data.length,
      min: min(arr),
      max: max(arr),
      mean: mean(arr),
      sd: std(arr),
      q25: quantileSeq(arr, 0.25),
      q50: quantileSeq(arr, 0.5),
      q75: quantileSeq(arr, 0.75),
    },
  };
}

/**
 * Gets a sub-list, between `start` and `end, from another list.
 * @param {*} data - sorted array of numbers
 * @param {*} start - first value of the boundary.
 * @param {*} end - last values of the boundary.
 * @returns a new array
 */
function between(data, start, end) {
  var b = data.findIndex(function (number) {
    return number >= start;
  });
  var e = data.findIndex(function (number) {
    return number >= end;
  });

  if (b >= 0 && e === -1) {
    e = data.length;
  }

  return data.slice(b, e);
}

/**
 * Gets the data to be plotted using the pie chart.
 * @param {*} data - array containing the values.
 * @param {*} n - maximum number of groups to be plotted.
 * @param {*} prec - number of decimals.
 * @returns The number of groups and their labels.
 */
function getPieData(data, n = 5, prec = 2) {
  data = data.sort();
  const counts = [];
  const labels = [];

  let val = data[0];
  let k = (data[data.length - 1] - data[0]) / n;
  for (let index = 0; index < n + 1; index++) {
    counts.push(between(data, val, val + k).length);
    labels.push(val.toFixed(prec) + "-" + (val + k).toFixed(prec));
    val += k;
  }

  return { counts, labels };
}
