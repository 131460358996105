import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  Paper,
  CircularProgress,
  experimentalStyled as styled,
  Box,
} from "@mui/material";
import { useFormik, getIn } from "formik";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

import { Input, Select } from "../../mui";
import { friService, aimService } from "../../services";
import * as options from "../../helpers/options";
import { mc2010 } from "../../helpers/mc2010";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top",
    },
    title: {
      display: false,
      text: "",
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "CMOD (mm)",
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value.toFixed(1) + "";
        },
      },
    },
    y: {
      title: {
        display: true,
        text: "Stress (MPa)",
      },
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value, index, ticks) {
          return value.toFixed(2) + "";
        },
      },
    },
  },
};

const initialChartData = {
  datasets: [
    {
      data: [],
    },
  ],
};

const FriPredict = () => {
  // { fR1: 1.5, fR3: 2.0 }
  const [fri, setFri] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(initialChartData);
  const [limits, setLimits] = useState();
  const [initialData] = React.useState({ lf: "", df: "", cf: "", fc: "" });

  useEffect(() => {
    const getModels = async () => {
      const m = await aimService.getFeatures("fri");
      setLimits(m);
    };

    getModels();

    setChartData(initialChartData);
  }, []);

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values) => {
    const errors = {};
    if (!values.lf) {
      errors.lf = "Required";
    } else if (values.lf < limits.lf.min) {
      errors.lf = `Must be greater than ${limits.lf.min}`;
    } else if (values.lf > limits.lf.max) {
      errors.lf = `Must be smaller than ${limits.lf.max}`;
    }

    if (!values.df) {
      errors.df = "Required";
    } else if (values.df < limits.df.min) {
      errors.df = `Must be greater than ${limits.df.min}`;
    } else if (values.df > limits.df.max) {
      errors.df = `Must be smaller than ${limits.df.max}`;
    }

    if (!values.cf) {
      errors.cf = "Required";
    } else if (values.cf < limits.cf.min) {
      errors.cf = `Must be greater than ${limits.cf.min}`;
    } else if (values.cf > limits.cf.max) {
      errors.cf = `Must be smaller than ${limits.cf.max}`;
    }

    if (!values.fc) {
      errors.fc = "Required";
    } else if (values.fc < limits.fc.min) {
      errors.fc = `Must be greater than ${limits.fc.min}`;
    } else if (values.fc > limits.fc.max) {
      errors.fc = `Must be smaller than ${limits.fc.max}`;
    }

    return errors;
  };

  const getClassification = (fR1, fR3) => {
    let ratio = fR3 / fR1;
    let letter = "";

    if (0.5 < ratio && ratio < 0.7) letter = "a";
    else if (0.7 <= ratio && ratio < 0.9) letter = "b";
    else if (0.9 <= ratio && ratio < 1.1) letter = "c";
    else if (1.1 <= ratio && ratio < 1.3) letter = "d";
    else if (1.1 <= ratio && ratio < 1.3) letter = "d";
    else if (1.3 <= ratio) letter = "e";

    return Math.floor(fR1) + letter;
  };

  const getPredictions = async (body) => {
    const data = await friService.post(body);

    if (data) {
      setFri(data);

      const newData = {
        datasets: [
          {
            showLine: true,
            lineTension: 0,
            label: "",
            data: [
              { x: 0.5, y: data.fR1 },
              { x: 2.5, y: data.fR3 },
            ],
            backgroundColor: "rgba(255, 99, 132, 1)",
            pointRadius: 3,
            pointBorderColor: "#99CCFF",
            pointBackgroundColor: "#99CCFF",
            borderColor: "#99CCFF",
          },
        ],
      };
      setChartData(newData);
      setLoading(false);
    }
  };

  const renderResults = () => {
    const Header = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      ...theme.typography.body,
      padding: theme.spacing(0.5),
      textAlign: "center",
      color: theme.palette.primary.contrastText,
    }));

    const Row = styled(Paper)(({ theme }) => ({
      // backgroundColor: theme.palette.mode === "dark" ? "#1A2045" : "#ffa",
      ...theme.typography.body,
      padding: theme.spacing(0.5),
      textAlign: "center",
      color: theme.palette.text.secondary,
    }));

    return (
      <>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 6, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Header>
              Classification: {getClassification(fri.fR1, fri.fR3)}
            </Header>
          </Grid>

          <Grid item xs={2} sm={3} md={3}>
            <Header>
              f<sub>R1</sub>
            </Header>
            <Row>{fri.fR1.toFixed(3)} MPa</Row>
          </Grid>

          <Grid item xs={2} sm={3} md={3}>
            <Header>
              f<sub>R3</sub>
            </Header>
            <Row>{fri.fR3.toFixed(3)} MPa</Row>
          </Grid>

          <Grid item xs={2} sm={3} md={3}>
            <Header>
              f<sub>R3</sub> / f<sub>R1</sub>{" "}
            </Header>
            <Row>{(fri.fR3 / fri.fR1).toFixed(3)}</Row>
          </Grid>

          <Grid item xs={2} sm={3} md={3}>
            <Header>
              f<sub>R1</sub> / f<sub>Lk</sub>{" "}
            </Header>
            <Row>
              {(fri.fR1 / mc2010.fctmMax(+formik.values.fc)).toFixed(3)}
            </Row>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderControls = () => {
    return (
      <Stack spacing={2}>
        <Select
          disabled
          required
          name="fibre.material"
          label="Material"
          tooltip="Fibre material type."
          options={options.fibreMatList}
          value="steel"
          error={Boolean(
            getIn(formik.touched, "fibre.material") &&
              getIn(formik.errors, "fibre.material")
          )}
          helperText={
            getIn(formik.touched, "fibre.material") &&
            getIn(formik.errors, "fibre.material")
          }
          onBlur={formik.handleBlur}
          onChange={() => formik.handleChange}
        />

        <Select
          disabled
          required
          name="fibre.geometry"
          label="Geometry"
          tooltip="Fibre geometry."
          options={options.fibreGeoList}
          value={"hooked"}
          error={Boolean(
            getIn(formik.touched, "fibre.geometry") &&
              getIn(formik.errors, "fibre.geometry")
          )}
          helperText={
            getIn(formik.touched, "fibre.geometry") &&
            getIn(formik.errors, "fibre.geometry")
          }
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <Input
          required
          number
          name="lf"
          label="Length"
          unit="mm"
          tooltip="Fibre length in mm."
          value={formik.values.lf}
          error={Boolean(
            getIn(formik.touched, "lf") && getIn(formik.errors, "lf")
          )}
          helperText={getIn(formik.touched, "lf") && getIn(formik.errors, "lf")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <Input
          required
          number
          name="df"
          label="Diameter"
          unit="mm"
          tooltip="Fibre diameter in mm."
          value={formik.values.df}
          error={Boolean(
            getIn(formik.touched, "df") && getIn(formik.errors, "df")
          )}
          helperText={getIn(formik.touched, "df") && getIn(formik.errors, "df")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <Input
          required
          number
          name="cf"
          label="C"
          sub="f"
          tooltip="Fibre content."
          unit="%"
          value={formik.values.cf}
          error={Boolean(
            getIn(formik.touched, "cf") && getIn(formik.errors, "cf")
          )}
          helperText={getIn(formik.touched, "cf") && getIn(formik.errors, "cf")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <Input
          required
          number
          name="fc"
          label="f"
          sub="cm"
          unit="MPa"
          tooltip="Mean cylinder compressive strength in MPa (Experimental result only, do not add calculated values)."
          value={formik.values.fc}
          error={Boolean(
            getIn(formik.touched, "fc") && getIn(formik.errors, "fc")
          )}
          helperText={getIn(formik.touched, "fc") && getIn(formik.errors, "fc")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="contained" type="submit" sx={{ width: "50%" }}>
            Predict
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              formik.resetForm();
              setChartData(initialChartData);
              setFri(null);
            }}
            sx={{ width: "50%" }}
          >
            Clear
          </Button>
        </Stack>
      </Stack>
    );
  };

  // Pass the useFormik() hook initial form values, a validate function that will be called when
  // form values change or fields are blurred, and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validate,
    onSubmit: (values) => {
      setLoading(true);
      const body = {
        fc: +values.fc,
        vf: +values.cf,
        ar: +values.lf / +values.df,
      };

      getPredictions(body);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center" gutterBottom>
            f<sub>Ri</sub> Predictor
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={4}>
          {renderControls()}
        </Grid>

        <Grid item xs={12} sm={7} sx={{ bg: "#f00" }}>
          {loading ? (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Stack>
              <Scatter options={chartOptions} data={chartData} />
              {fri && renderResults()}
            </Stack>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default FriPredict;
