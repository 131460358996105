import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Typography, Grid, TextField, Paper } from "@mui/material";
// App
import { accountService, alertService } from "../../services";

function ResetPassword({ history }) {
  const TokenStatus = {
    Validating: "Validating",
    Valid: "Valid",
    Invalid: "Invalid",
  };
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

  useEffect(() => {
    const { token } = queryString.parse(window.location.search);

    // remove token from url to prevent http referer leakage
    navigate(window.location.pathname, { replace: true });

    accountService
      .validateResetToken(token)
      .then(() => {
        setToken(token);
        setTokenStatus(TokenStatus.Valid);
      })
      .catch(() => {
        setTokenStatus(TokenStatus.Invalid);
      });
    // eslint-disable-next-line
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: validationSchema,

    onSubmit: ({ password, confirmPassword }, { setSubmitting }) => {
      alertService.clear();
      accountService
        .resetPassword({ token, password, confirmPassword })
        .then(() => {
          alertService.success("Password reset successful, you can now login", {
            keepAfterRouteChange: true,
          });
          history.push("login");
        })
        .catch((error) => {
          setSubmitting(false);
          alertService.error(error);
        });
    },
  });

  const getForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">Reset Password</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="contained" type="submit">
              Reset Password
            </Button>{" "}
          </Grid>
        </Grid>
      </form>
    );
  };

  function getBody() {
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return getForm();
      case TokenStatus.Invalid:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Reset Password</Typography>
            </Grid>
            <Grid item xs={12}>
              Token validation failed, if the token has expired you can get a
              new one at the{" "}
              <NavLink to="/account/forgot-password">forgot Password</NavLink>{" "}
              page.
            </Grid>
          </Grid>
        );
      case TokenStatus.Validating:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Reset Password</Typography>
            </Grid>
            <Grid item xs={12}>
              Validating token...
            </Grid>
          </Grid>
        );
      default:
        return;
    }
  }

  return (
    <Paper elevation={0} sx={{ width: 600, m: "20px auto" }}>
      {getBody()}
    </Paper>
  );
}

export default ResetPassword;
