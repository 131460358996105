import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { getIn } from "formik";
import { BiCylinder } from "react-icons/bi";

// Aoo
import * as options from "../../helpers/options";
import { Input, Select } from "../../mui";
import Section from "./Section";

const FcForm = ({ formik }) => {
  const [unitFcDisp, setUnitFcDisp] = React.useState("%");

  const getLabel = (label) => {
    const id = options.stdOrgList.findIndex((e) => e.label === label);
    return options.stdOrgList[id];
  };

  return (
    <Section title="Compressive Strength" icon={<BiCylinder />}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            value={getLabel(formik.values.fc.standard)}
            options={options.stdOrgList}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(e, value) => {
              if (value != null) {
                formik.setFieldValue("fc.standard", value.label);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={"fc.standard"}
                label="Standard"
                variant="standard"
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            required
            number
            name="fc.value"
            label="f"
            sub="cm"
            unit="MPa"
            tooltip="Mean compressive strength in MPa (Experimental result only, do not add calculated values)."
            value={formik.values.fc.value}
            error={Boolean(
              getIn(formik.touched, "fc.value") &&
                getIn(formik.errors, "fc.value")
            )}
            helperText={
              getIn(formik.touched, "fc.value") &&
              getIn(formik.errors, "fc.value")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Select
            required
            name="fc.shape"
            label="Specimen Shape"
            tooltip="Concrete specimen shape used in the compressive test."
            value={formik.values.fc.shape}
            options={options.fcShapeList}
            error={Boolean(
              getIn(formik.touched, "fc.shape") &&
                getIn(formik.errors, "fc.shape")
            )}
            helperText={
              getIn(formik.touched, "fc.shape") &&
              getIn(formik.errors, "fc.shape")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            required
            number
            name="fc.age"
            label="Age"
            tooltip="Concrete compressive strength age in days."
            unit="Days"
            value={formik.values.fc.age}
            error={Boolean(
              getIn(formik.touched, "fc.age") && getIn(formik.errors, "fc.age")
            )}
            helperText={
              getIn(formik.touched, "fc.age") && getIn(formik.errors, "fc.age")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            name="fc.ns"
            label="Nr. Specimens"
            tooltip="Number of cylinder (or cube) used in the compressive strength test."
            value={formik.values.fc.ns}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Select
            name="fc.measure"
            label="Dispersion"
            options={options.measureLists}
            value={formik.values.fc.measure}
            tooltip="Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              setUnitFcDisp(e.target.value === "cv" ? "%" : "MPa");
            }}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            number
            name="fc.mvalue"
            label="Value"
            unit={unitFcDisp}
            tooltip="Measure of dispersion value."
            value={formik.values.fc.mvalue}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Section>
  );
};

export default FcForm;
