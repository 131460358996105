import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import queryString from "query-string";

// App
import { accountService, alertService } from "../../services";

function VerifyEmail() {
  const navigate = useNavigate();
  const EmailStatus = {
    Verifying: "Verifying",
    Failed: "Failed",
  };

  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying);

  useEffect(() => {
    const { token } = queryString.parse(window.location.search);

    // remove token from url to prevent http referer leakage
    navigate(window.location.pathname, { replace: true });

    accountService
      .verifyEmail(token)
      .then(() => {
        alertService.success("Verification successful, you can now login", {
          keepAfterRouteChange: true,
        });
        navigate("/account/login");
      })
      .catch(() => {
        setEmailStatus(EmailStatus.Failed);
      });
    // eslint-disable-next-line
  }, []);

  function getBody() {
    switch (emailStatus) {
      case EmailStatus.Verifying:
        return <div>Verifying...</div>;
      case EmailStatus.Failed:
        return (
          <div>
            Verification failed, you can also verify your account using the{" "}
            <NavLink to="/account/forgot-password">forgot Password</NavLink>
            page.
          </div>
        );
      default:
        return;
    }
  }

  return (
    <div>
      <h3 className="card-header">Verify Email</h3>
      <div className="card-body">{getBody()}</div>
    </div>
  );
}

export default VerifyEmail;
