import React from "react";
import {
  Tooltip,
  FormControl,
  FormHelperText,
  InputAdornment,
  Input as MuiInput,
  InputLabel,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat 
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      // thousandSeparator
      isNumericString
      // prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function Input(props) {
  const {
    required,
    fullWidth = true,
    label,
    icon,
    sub,
    tooltip,
    number = false,
    multiline = false,
    unit,
    supUnit,
    posUnit,
    helperText,
    error = false,
    onChange,
    onClick,
    onIconClick,
    variant = "standard",
    ...other
  } = props;

  return (
    <Tooltip title={tooltip ? tooltip : ""} arrow>
      <FormControl required={required} fullWidth={fullWidth} variant={variant}>
        <InputLabel>
          {label}
          {sub ? <sub>{sub}</sub> : ""}
        </InputLabel>

        {number ? (
          <MuiInput
            onChange={onChange}
            {...(error && { error: true })}
            {...other}
            startAdornment={
              <InputAdornment position="start">{""}</InputAdornment>
            }
            endAdornment={
              unit && (
                <InputAdornment position="end">
                  {unit}
                  <sup>{supUnit}</sup>
                  {posUnit}
                </InputAdornment>
              )
            }
            inputComponent={NumberFormatCustom}
          />
        ) : (
          <MuiInput
            multiline={multiline}
            onChange={onChange}
            {...(error && { error: true })}
            {...other}
            startAdornment={
              <InputAdornment position="start">{""}</InputAdornment>
            }
            endAdornment={
              icon && <IconButton onClick={onIconClick}>{icon}</IconButton>
            }
          />
        )}

        {error && <FormHelperText error>{helperText}</FormHelperText>}
      </FormControl>
    </Tooltip>
  );
}
