import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const MyAlert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function useAlert() {
  const [alert, setAlert] = useState({
    open: false,
    type: "success",
    message: "",
  });

  const Alert = ({ duration = 3000 }) => {
    return (
      <Snackbar
        open={alert.open}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        {alert.type === "success" ? (
          <MyAlert
            onClose={() => setAlert({ ...alert, open: false })}
            severity="success"
          >
            {alert.message}
          </MyAlert>
        ) : alert.type === "warning" ? (
          <MyAlert
            onClose={() => setAlert({ ...alert, open: false })}
            severity="warning"
          >
            {alert.message}
          </MyAlert>
        ) : (
          <MyAlert
            onClose={() => setAlert({ ...alert, open: false })}
            severity="error"
          >
            {alert.message}
          </MyAlert>
        )}
      </Snackbar>
    );
  };

  return {
    setAlert,
    Alert,
  };
}
