import * as React from "react";
import { Routes, Route } from "react-router-dom";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { blue, lightBlue, indigo } from "@mui/material/colors";
// App imports
import ColorModeContext from "./contexts/ColorModeContext";
import { accountService } from "./services";
import { Alert, Nav, PrivateRoute } from "./components";
import Account from "./pages/account/Account";
import Login from "./pages/account/Login";
import ForgotPassword from "./pages/account/ForgotPassword";
import Register from "./pages/account/Register";
import VerifyEmail from "./pages/account/VerifyEmail";
import ResetPassword from "./pages/account/ResetPassword";
import Home from "./pages/Home";
import Database from "./pages/tools/Database";
import FrcForm from "./pages/frc/FrcForm";
import UserDataForm from "./pages/profile/UserDataForm";
import Footer from "./components/Footer";
import FriPredict from "./pages/tools/FriPredict";
import About from "./pages/About";
import Statistics from "./pages/Statistics";
import DataTable from "./pages/admin/DataTable";
import UsersTable from "./pages/admin/UsersTable";

const App = () => {
  const [user, setUser] = React.useState({});
  const storedDarkMode = localStorage.getItem("DARK_MODE");
  // If localStorage variable does not exist it sets the theme according
  // to the system theme.
  const [mode, setMode] = React.useState(() =>
    !storedDarkMode
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      : storedDarkMode
  );

  React.useEffect(() => {
    // Trick to avoid warning for not using user variable.
    setUser(user);
    const subscription = accountService.user.subscribe((x) => setUser(x));
    return subscription.unsubscribe;

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    // Persist mode
    localStorage.setItem("DARK_MODE", mode);
  }, [mode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const getPalette = (mode) => {
    if (mode === "dark") {
      return createTheme({
        palette: {
          mode,
          primary: blue,
          secondary: lightBlue,
          Container: "#18272E",
          background: {
            paper: "#18272E",
            default: "#18272E",
          },
        },
      });
    } else {
      return createTheme({
        palette: {
          mode,
          primary: indigo,
          secondary: lightBlue,
        },
      });
    }
  };

  const theme = React.useMemo(() => getPalette(mode), [mode]);

  const render = () => {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Nav />

          <Alert />

          <Container maxWidth="lg" sx={{ margin: "20px auto" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/database" element={<Database />} />
              <Route path="/statistics" element={<Statistics />} />
              <Route
                path="/add"
                element={
                  <PrivateRoute>
                    <FrcForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/edit/:id"
                element={
                  <PrivateRoute>
                    <FrcForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/user-data"
                element={
                  <PrivateRoute>
                    <UserDataForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin-data"
                element={
                  <PrivateRoute>
                    <DataTable />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin-users"
                element={
                  <PrivateRoute>
                    <UsersTable />
                  </PrivateRoute>
                }
              />

              <Route path="/account/*" element={<Account />}>
                <Route path={"login"} element={<Login />} />
                <Route path={"forgot-password"} element={<ForgotPassword />} />
                <Route path={"register"} element={<Register />}>
                  <Route path={":id"} element={<Register />} />
                </Route>
                <Route path={"verify-email"} element={<VerifyEmail />} />
                <Route path={"reset-password"} element={<ResetPassword />} />
              </Route>

              <Route path="/predict" element={<FriPredict />} />
            </Routes>
          </Container>

          <Footer />
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  };

  return render();
};

export default App;
