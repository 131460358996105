import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

// image: 690 x 388
const Tools = ({ title, description, image, link }) => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <NavLink to={link} style={{ color: "white", textDecoration: "none" }}>
        <CardActionArea>
          <CardMedia component="img" height="140" image={image} />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              color="text.primary"
            >
              {title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </NavLink>
    </Card>
  );
};

export default Tools;
