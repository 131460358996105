import React from "react";
import {
  Tooltip,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@mui/material";

export default function Select(props) {
  const {
    required,
    fullWidth = true,
    name,
    label,
    value,
    error = false,
    onChange,
    options,
    helperText,
    disabled = false,
    tooltip,
    variant = "standard",
  } = props;

  return (
    <FormControl
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      variant={variant}
      error={error}
    >
      <Tooltip title={tooltip ? tooltip : ""} arrow>
        <InputLabel>{label}</InputLabel>
      </Tooltip>

      <MuiSelect label={label} name={name} value={value} onChange={onChange}>
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>

      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}
