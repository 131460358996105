import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Paper, Typography } from "@mui/material";
// App
import { accountService, alertService } from "../../services";

function ForgotPassword() {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      email: "",
    },
    onSubmit: ({ email }, { setSubmitting }) => {
      alertService.clear();
      accountService
        .forgotPassword(email)
        .then(() =>
          alertService.success(
            "Please check your email for password reset instructions"
          )
        )
        .catch((error) => alertService.error(error))
        .finally(() => setSubmitting(false));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} sx={{ width: 300, m: "20px auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Forgot Password</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            <Grid item xs={12}>
              <Button color="primary" variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </div>
  );
}

export default ForgotPassword;
