import React from "react";
import { Link, Stack, Typography, Grid } from "@mui/material";
import { FaOrcid } from "react-icons/fa";

const People = ({ name, url }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>{name}</Typography>
      <Link underline="none" target="_blank" rel="noreferrer" href={url}>
        <FaOrcid />
      </Link>
    </Stack>
  );
};

const Section = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Typography variant="h4">{props.title}</Typography>
      </Grid>
      <Grid item sm={12}>
        {props.children}
      </Grid>
      <Grid item sm={12}></Grid>
    </Grid>
  );
};

const About = () => {
  return (
    <Stack>
      <Section title="About">
        <Stack spacing={2}>
          <Typography variant="body1" align="justify">
            The AIpFRC is one of the outputs of the FemWebAI project (Integrated
            approach for reliable and advanced analysis and design of
            sustainable construction systems in fibre reinforced concrete),
            supported by{" "}
            <Link
              underline="none"
              target="_blank"
              rel="noreferrer"
              href="https://www.fct.pt/index.phtml.pt"
            >
              Portuguese Science Foundation (FCT)
            </Link>
            , with reference PTDC/ECI-EST/6300/2020. The FemWebAI aims to
            develop an integrated methodology involving:
          </Typography>

          <Typography variant="body1" align="justify">
            1) the execution of a series of international blind simulation
            competitions (BSC) of experimental programs with representative FRC
            structures of real scale and using the most advanced monitoring
            technology;
          </Typography>
          <Typography variant="body1" align="justify">
            2) the development of a Web-based information platform including a
            database supported by artificial intelligence (AI) techniques for
            collecting the results of BSC and reliable experimental results of
            the scientific community in a continuous and dynamic feeding;
          </Typography>
          <Typography variant="body1" align="justify">
            3) parametric studies and sensitivity analysis for assessing the
            influence of the models' parameters on their predictive performance;
          </Typography>
          <Typography variant="body1" align="justify">
            4) the development of reliable methodologies for FEM-base design of
            FRC structures by modelling explicitly the fibres and the cracks and
            using a holistic vision of their structural behaviour assisted by
            machine learning and inverse analysis techniques.
          </Typography>
        </Stack>
      </Section>

      <Section title="People">
        <Stack>
          <People
            name="Joaquim Barros (coordinator)"
            url="https://orcid.org/0000-0003-1528-757X"
          />
          <People
            name="Fábio P. Figueiredo"
            url="https://orcid.org/my-orcid?orcid=0000-0001-5560-4969"
          />
          <People
            name="Paulo Cortez"
            url="https://orcid.org/0000-0002-7991-2090"
          />
          <People
            name="José Sena Cruz"
            url="https://orcid.org/0000-0003-3048-1290"
          />
          <People
            name="Ventura Gouveia"
            url="https://orcid.org/0000-0002-1057-8733"
          />
          <People
            name="Vitor Cunha"
            url="https://orcid.org/0000-0003-3580-4271"
          />
          <People
            name="Tiago Valente"
            url="https://orcid.org/0000-0001-6647-3255"
          />
          <People
            name="Hamidreza Salehian"
            url="https://orcid.org/0000-0002-7924-1442"
          />
        </Stack>
      </Section>
    </Stack>
  );
};

export default About;
