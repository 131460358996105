import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { frcService } from "../../services";
import { Box } from "@mui/system";
import { CircularProgress, Stack, Typography } from "@mui/material";

const getHeaders = () => {
  const header = (field, headerName, description, hide = true, width = 150) => {
    return { field, headerName, hide, description, width };
  };

  const agg = () => {
    const size = 5;
    let aggs = [];
    for (let i = 0; i < size; i++) {
      let obj = [
        header(
          `mix_agg_${i + 1}_type`,
          `Mix: Agg ${i + 1} (Type)`,
          "Aggregate type."
        ),
        header(
          `mix_agg_${i + 1}_mat`,
          `Mix: Agg ${i + 1} (Mat)`,
          "Aggregate material."
        ),
        header(
          `mix_agg_${i + 1}_quantity`,
          `Mix: Agg ${i + 1} (Qtd)`,
          "Aggregate quantity."
        ),
        header(
          `mix_agg_${i + 1}_size`,
          `Mix: Agg ${i + 1} (Size)`,
          "Aggregate size."
        ),
      ];
      aggs = [...aggs, ...obj];
    }
    return aggs;
  };

  const addons = () => {
    const size = 3;
    let add = [];
    for (let i = 0; i < size; i++) {
      let obj = [
        header(
          `mix_addons_${i + 1}_type`,
          `Mix: Addons ${i + 1} (Type)`,
          "Additions type."
        ),
        header(
          `mix_addons_${i + 1}_brand`,
          `Mix: Addons ${i + 1} (Brand)`,
          "Additions brand."
        ),
        header(
          `mix_addons_${i + 1}_quantity`,
          `Mix: Addons ${i + 1} (Qtd)`,
          "Additions quantity."
        ),
      ];
      add = [...add, ...obj];
    }
    return add;
  };

  const addves = () => {
    const size = 3;
    let add = [];
    for (let i = 0; i < size; i++) {
      let obj = [
        header(
          `mix_addves_${i + 1}_type`,
          `Mix: Addves ${i + 1} (Type)`,
          "Additives type."
        ),
        header(
          `mix_addves_${i + 1}_brand`,
          `Mix: Addves ${i + 1} (Brand)`,
          "Additives brand."
        ),
        header(
          `mix_addves_${i + 1}_quantity`,
          `Mix: Addves ${i + 1} (Quantity)`,
          "Additives quantity."
        ),
      ];
      add = [...add, ...obj];
    }
    return add;
  };

  const fri = () => {
    const size = 6;
    let col = [];
    for (let i = 0; i < size; i++) {
      let obj = [
        header(
          `fri_value_${i + 1}`,
          `fR${i + 1}`,
          "Residual flexural tensile strength.",
          false,
          100
        ),
        header(
          `fri_od_${i + 1}`,
          `fR${i + 1} CMOD/TCOD `,
          "Crack mouth opening displacement or Total Circumferential Opening Displacement"
        ),
        header(
          `fri_measure_${i + 1}`,
          `fRi${i + 1} CV/SD`,
          "Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
        ),
        header(
          `fri_mvalue_${i + 1}`,
          `fRi${i + 1} Value `,
          "Measure of dispersion value."
        ),
      ];
      col = [...col, ...obj];
    }

    return col;
  };

  const general = [
    { field: "id", headerName: "ID" },
    header(
      "doi",
      "DOI",
      "Type the DOI number of the reference and click on the search button.",
      true,
      50
    ),
    header("reference", "Reference", "Full reference."),
    header(
      "standard",
      "Standard",
      "Test standard used to obtain the residual flexural tensile strength.",
      false
    ),
    header(
      "cname",
      "Designation",
      "Fibre reinforced concrete designation, e.g. SSFRC, SHFRC, etc"
    ),
    header(
      "specimen",
      "Specimen",
      "Original name of the specimen used by the authors in the paper."
    ),
    header(
      "ns",
      "No. specimens",
      "Number of specimens used in the flexural test."
    ),
  ];

  const mix = [
    header("mix_units", "Mix: Units", "Unit of the materials."),
    header("mix_water", "Mix: Water", "Water quantity."),
    header("mix_cement", "Mix: Cement", "Cement quantity."),
    header("mix_cement_type", "Mix: Type", "Cement Type."),
    header("mix_wc", "Mix: W/C", "Water/cement ratio."),
    header("mix_cf", "Mix: Cf", "Fibre content."),
  ];

  const fibre = [
    // Fibre properties
    header("fibre_mat", "Fibre: Material", "Fibre material.", false),
    header("fibre_geo", "Fibre: Geometry", "Fibre geometry.", false),
    header("fibre_lf", "Fibre: Length", "Fibre length."),
    header("fibre_df", "Fibre: Diameter", "Fibre diameter."),
    header("fibre_ef", "Fibre: Elasticity", "Fibre modulus of elasticity."),
    header("fibre_ffu", "Fibre: Stress", "Fibre tensile strength."),
  ];

  const fc = [
    // Fibre properties
    header("fc", "Fc", "Concrete mean compressive strength.", false),
    header(
      "fc_code",
      "Fc: Standard",
      "Test standard used to determine the concrete compressive strength"
    ),
    header(
      "fc_shape",
      "Fc: Shape",
      "Concrete specimen shape used in the compressive test."
    ),
    header("fc_age", "Fc: Age", "Concrete compressive strength age in days.."),
    header(
      "fc_ns",
      "Fc: No. specimens",
      "Number of cylinder (or cube) used in the compressive strength test."
    ),
    header(
      "fc_measure",
      "Fc: CV/SD",
      "Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
    ),
    header("fc_mvalue", "Fc: Value", "Measure of dispersion value."),
  ];

  const ft = [
    // Fibre properties
    header("ft", "Ft", "Concrete mean tensile strength.", false),
    header(
      "ft_code",
      "Ft: Standard",
      "Test standard used to determine the concrete tensile strength"
    ),
    header(
      "ft_ns",
      "Ft: No. specimens",
      "Number of specimens used in the tensile strength test."
    ),
    header(
      "ft_measure",
      "Ft: CV/SD",
      "Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
    ),
    header("ft_mvalue", "Ft: Value", "Measure of dispersion value."),
  ];

  const ec = [
    // Fibre properties
    header("ec", "Ec", "Concrete modulus of elasticity.", false),
    header(
      "ec_code",
      "Ec: Standard",
      "Test standard used to determine the modulus of elasticity."
    ),
    header(
      "ec_ns",
      "Ec: No. specimens",
      "Number of specimens used in the modulus of elasticity test."
    ),
    header(
      "ec_measure",
      "Ec: CV/SD",
      "Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
    ),
    header("et_mvalue", "Ec: Value", "Measure of dispersion value."),
  ];

  return [
    ...general,
    ...mix,
    ...agg(),
    ...addons(),
    ...addves(),
    ...fibre,
    ...fri(),
    ...fc,
    ...ft,
    ...ec,
  ];
};

const createRow = (id, data) => {
  const createAggRow = (data) => {
    const size = data.mix.aggregates.length;
    let aggs = {};

    for (let i = 0; i < size; i++) {
      const a = data.mix.aggregates[i];
      let obj = {
        [`mix_agg_${i + 1}_type`]: a.type,
        [`mix_agg_${i + 1}_mat`]: a.material,
        [`mix_agg_${i + 1}_quantity`]: a.quantity,
        [`mix_agg_${i + 1}_size`]: a.size,
      };
      aggs = Object.assign(aggs, obj);
    }

    for (let i = size; i < 5; i++) {
      let obj = {
        [`mix_agg_${i + 1}_type`]: "",
        [`mix_agg_${i + 1}_mat`]: "",
        [`mix_agg_${i + 1}_quantity`]: "",
        [`mix_agg_${i + 1}_size`]: "",
      };
      aggs = Object.assign(aggs, obj);
    }

    return aggs;
  };

  const createAdds = (data, option) => {
    let adds = {};
    const size =
      option === "addons"
        ? data.mix.additions.length
        : data.mix.additives.length;

    for (let i = 0; i < size; i++) {
      const a =
        option === "addons" ? data.mix.additions[i] : data.mix.additives[i];

      let obj = {
        [`mix_${option}_${i + 1}_type`]: a.type,
        [`mix_${option}_${i + 1}_brand`]: a.brand,
        [`mix_${option}_${i + 1}_quantity`]: a.quantity,
      };
      adds = Object.assign(adds, obj);
    }

    for (let i = size; i < 3; i++) {
      let obj = {
        [`mix_${option}_${i + 1}_type`]: "",
        [`mix_${option}_${i + 1}_brand`]: "",
        [`mix_${option}_${i + 1}_quantity`]: "",
      };
      adds = Object.assign(adds, obj);
    }

    return adds;
  };

  const createFris = (data) => {
    let fris = {};
    const size = data.fRi.length;

    for (let i = 0; i < size; i++) {
      const a = data.fRi[i];

      let obj = {
        [`fri_od_${i + 1}`]: a.disp,
        [`fri_value_${i + 1}`]: a.value,
        [`fri_measure_${i + 1}`]: a.measure,
        [`fri_mvalue_${i + 1}`]: a.mvalue,
      };
      fris = Object.assign(fris, obj);
    }

    for (let i = size; i < 6; i++) {
      let obj = {
        [`fri_od_${i + 1}`]: "",
        [`fri_value_${i + 1}`]: "",
        [`fri_measure_${i + 1}`]: "",
        [`fri_mvalue_${i + 1}`]: "",
      };
      fris = Object.assign(fris, obj);
    }

    return fris;
  };

  let rows = {
    id: id,
    doi: data.doi,
    reference: data.reference,
    standard: data.standard,
    specimen: data.specimen,
    ns: data.ns,
    cname: data.cname,
    // Mix composition
    mix_units: data.mix.unit,
    mix_water: data.mix.water,
    mix_cement: data.mix.cement.value,
    mix_cement_type: data.mix.cement.type,
    mix_wc: data.mix.wc,
    mix_cf: data.mix.cf,
    // Fibre properties
    fibre_mat: data.fibre.material,
    fibre_geo: data.fibre.geometry,
    fibre_lf: data.fibre.length,
    fibre_df: data.fibre.diameter,
    fibre_ef: data.fibre.young,
    fibre_ffu: data.fibre.fu,
    // Concrete compressive strength
    fc: data.fc.value,
    fc_code: data.fc.standard,
    fc_shape: data.fc.shape,
    fc_age: data.fc.age,
    fc_ns: data.fc.ns,
    fc_measure: data.fc.measure,
    fc_mvalue: data.fc.mvalue,
    // Concrete tensile strength
    ft: data.ft.value,
    ft_code: data.ft.standard,
    ft_ns: data.ft.ns,
    ft_measure: data.ft.measure,
    ft_mvalue: data.ft.mvalue,
    // Concrete modulus of elasticity
    ec: data.ec.value,
    ec_code: data.ec.standard,
    ec_ns: data.ec.ns,
    ec_measure: data.ec.measure,
    ec_mvalue: data.ec.mvalue,
  };

  return Object.assign(
    rows,
    createAggRow(data),
    createAdds(data, "addons"),
    createAdds(data, "addves"),
    createFris(data)
  );
};

const Database = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getRecords = async () => {
      const rows = [];
      const data = await frcService.getAll();
      if (data) {
        data.map((e, index) => {
          return rows.push(createRow(index + 1, e));
        });
      }
      setRecords(rows);
      setLoading(false);
    };

    getRecords();
  }, []);

  const render = () => {
    return (
      <div style={{ height: 1000, width: "100%" }}>
        {records.length > 0 ? (
          <DataGrid
            rows={records}
            columns={getHeaders()}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body" sx={{ p: 3 }}>
              No data available.
            </Typography>
            <CircularProgress />
          </Box>
        )}
      </div>
    );
  };

  return (
    <div>
      {loading ? (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="body" sx={{ p: 3 }}>
            Loading data, please wait...
          </Typography>
          <CircularProgress />
        </Stack>
      ) : (
        render()
      )}
    </div>
  );
};

export default Database;
