import React from "react";
import { Grid } from "@mui/material";
import { getIn } from "formik";
import { SiWire } from "react-icons/si";

// App
import { Input, Select } from "../../mui";
import * as options from "../../helpers/options";
import Section from "./Section";

const FibreForm = ({ formik }) => {
  return (
    <Section title="Fibre Properties" icon={<SiWire />}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <Select
            required
            name="fibre.material"
            label="Material"
            tooltip="Fibre material type."
            options={options.fibreMatList}
            value={formik.values.fibre.material}
            error={Boolean(
              getIn(formik.touched, "fibre.material") &&
                getIn(formik.errors, "fibre.material")
            )}
            helperText={
              getIn(formik.touched, "fibre.material") &&
              getIn(formik.errors, "fibre.material")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Select
            required
            name="fibre.geometry"
            label="Geometry"
            tooltip="Fibre geometry."
            options={options.fibreGeoList}
            value={formik.values.fibre.geometry}
            error={Boolean(
              getIn(formik.touched, "fibre.geometry") &&
                getIn(formik.errors, "fibre.geometry")
            )}
            helperText={
              getIn(formik.touched, "fibre.geometry") &&
              getIn(formik.errors, "fibre.geometry")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Input
            required
            number
            name="fibre.length"
            label="Length"
            unit="mm"
            value={formik.values.fibre.length}
            tooltip="Fibre length in mm."
            error={Boolean(
              getIn(formik.touched, "fibre.length") &&
                getIn(formik.errors, "fibre.length")
            )}
            helperText={
              getIn(formik.touched, "fibre.length") &&
              getIn(formik.errors, "fibre.length")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Input
            required
            number
            name="fibre.diameter"
            label="Diameter"
            unit="mm"
            tooltip="Fibre diameter in mm."
            value={formik.values.fibre.diameter}
            error={Boolean(
              getIn(formik.touched, "fibre.diameter") &&
                getIn(formik.errors, "fibre.diameter")
            )}
            helperText={
              getIn(formik.touched, "fibre.diameter") &&
              getIn(formik.errors, "fibre.diameter")
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Input
            number
            name="fibre.young"
            label="E"
            sub="f"
            unit="MPa"
            tooltip="Fibre modulus of elasticity in MPa (Experimental result only, do not add calculated values)."
            value={formik.values.fibre.young}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Input
            number
            name="fibre.fu"
            label="F"
            sub="fu"
            unit="MPa"
            tooltip="Fibre tensile strength in MPa."
            value={formik.values.fibre.fu}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Section>
  );
};

export default FibreForm;
