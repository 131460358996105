import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  CircularProgress,
  Stack,
  Typography,
  Alert as AlertMui,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TerminalIcon from "@mui/icons-material/Terminal";

// App
import { accountService, frcService } from "../../services";
import ConfirmDialog from "../../mui/ConfirmDialog";
import useAlert from "../../hooks/useAlert";
import GeneralForm from "./GeneralForm";
import FcForm from "./FcForm";
import FtForm from "./FtForm";
import EcForm from "./EcForm";
import FibreForm from "./FibreForm";
import MixForm from "./MixForm";
import FriForm from "./FriForm";

yup.addMethod(yup.array, "unique", function (message, mapper = (a) => a) {
  return this.test("unique", message, function (list) {
    return list.length === new Set(list.map(mapper)).size;
  });
});

const validationSchema = yup.object().shape({
  //doi: yup.string().matches(/^10.\d{4,9}/, "DOI is not valid."),
  reference: yup.string().required("Field is required"),
  specimen: yup.string().required("Field is required"),
  ns: yup.number().required("Field is required").min(1, "Min value is 1"),
  fRi: yup
    .array()
    .of(
      yup.object().shape({
        disp: yup.string(),
        value: yup.number().required("Field is required"),
      })
    )
    .unique("duplicate", (a) => a.disp),
  fibre: yup.object().shape({
    material: yup.string().required("Field is required"),
    geometry: yup.string().required("Field is required"),
    length: yup.number().required("Field is required"),
    diameter: yup.number().required("Field is required"),
  }),
  mix: yup.object().shape({
    water: yup.number(),
    cement: yup.object().shape({
      value: yup.number(),
    }),
    wc: yup.number(),
    cf: yup.number().required("Field is required"),
    aggregates: yup.array().of(
      yup.object().shape({
        quantity: yup.number(),
        size: yup
          .string()
          .matches(
            /^(([0-9]*\.[0-9]+|[0-9]+))(?:-([0-9]*\.[0-9]+|[0-9]+))?$/gm,
            "Size is not valid"
          ),
      })
    ),
  }),
  fc: yup.object().shape({
    value: yup.number().required("Field is required"),
    age: yup.number().required("Field is required"),
    shape: yup.string().required("Field is required"),
  }),
});

const FrcForm = () => {
  const params = useParams();
  const id = params.id;
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [initialData, setInitialData] = React.useState(frcService.reset());
  const [loading, setLoading] = React.useState(true);
  const [check, setCheck] = React.useState([]);
  const { Alert, setAlert } = useAlert({
    open: false,
    type: "success",
    message: "",
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const checks = frcService.check(values);

      // If any error or warning is found...
      if (checks.length > 0) {
        let errors = [];
        for (let index = 0; index < checks.length; index++) {
          const e = checks[index];
          if (e.severity === "error") errors.push(e);
        }

        // In case of warning confirm the action before store to
        // the database.
        if (errors.length === 0) {
          setConfirmOpen(true);
        }
      } else {
        handleConfirm();
      }

      setCheck(checks);
    },
  });

  function add(data) {
    const user = accountService.userValue;
    // For dropbox it is necessary to convert from index to strings
    // before saving.
    let newdata = {
      ...data,
      user: user.email,
    };

    return frcService
      .post(newdata)
      .then((res) => {
        setAlert({
          open: true,
          type: "success",
          message: "Record successfully saved into the database.",
        });
      })
      .catch((error) => {
        let message = error;
        if (error.includes("E11000")) {
          message = "The database already contains this record.";
        }

        setAlert({
          open: true,
          type: "error",
          message: message,
        });
      });
  }

  function update(id, data) {
    return frcService
      .update(id, data)
      .then(() => {
        setAlert({
          open: true,
          type: "success",
          message: "Record updated successfully.",
        });
      })
      .catch((error) => {
        setAlert({
          open: true,
          type: "error",
          message: error,
        });
      });
  }

  useEffect(() => {
    const getById = async () => {
      let frc = {};
      if (!id) {
        frc = frcService.reset();
      } else {
        frc = await frcService.getById(id);
      }

      setInitialData(frc);
      setLoading(false);
    };

    getById();
    // eslint-disable-next-line
  }, []);

  const renderCheck = () => {
    return (
      <>
        {check.map((e, index) => (
          <AlertMui severity={e.severity} key={index}>
            {e.message}
          </AlertMui>
        ))}
      </>
    );
  };

  const handleConfirm = () => {
    let v = { ...formik.values };
    !id ? add(v) : update(id, v);
    // Clear checks
    setCheck([]);
  };

  return (
    <div>
      {loading ? (
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Stack spacing={3}>
          <form onSubmit={formik.handleSubmit}>
            {check.map((e, id) => (
              <div key={id} className="alert alert-danger">
                {e.msg}
              </div>
            ))}
            <GeneralForm formik={formik} />
            <MixForm formik={formik} />
            <FibreForm formik={formik} />
            <FriForm formik={formik} />
            <FcForm formik={formik} />
            <FtForm formik={formik} />
            <EcForm formik={formik} />
            <Stack
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 8 }}
            >
              <Button variant="contained" type="submit" sx={{ width: "80px" }}>
                Submit
              </Button>
              <Button
                variant="contained"
                type="reset"
                sx={{ width: "80px" }}
                onClick={formik.resetForm}
              >
                Clear
              </Button>
            </Stack>
          </form>
          <Stack></Stack>
          <Accordion variant="outlined">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <Badge badgeContent={check.length} color="primary">
                  <TerminalIcon />
                </Badge>

                <Typography>Output</Typography>
              </Stack>
            </AccordionSummary>

            <AccordionDetails>{renderCheck()}</AccordionDetails>
          </Accordion>
          <Alert />
        </Stack>
      )}

      <ConfirmDialog
        title="The data you are about to submit has warnings, do you want to proceed?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleConfirm}
      >
        {check.map((e, index) => (
          <AlertMui severity={e.severity} key={index}>
            {e.message}
          </AlertMui>
        ))}
      </ConfirmDialog>
    </div>
  );
};

export default FrcForm;
