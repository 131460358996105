import React from "react";
import {
  Tooltip,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";

export default function SelectCountry(props) {
  const {
    required,
    fullWidth = true,
    name,
    label,
    value = "",
    error = false,
    onChange,
    disabled = false,
    tooltip,
    helperText,
    variant = "standard",
  } = props;

  // const [selectedCountry, setSelectedCountry] = React.useState("");

  countries.registerLocale(enLocale);

  //const selectCountryHandler = (value) => setSelectedCountry(value);

  // Returns an object not a list
  const countryObj = countries.getNames("en", { select: "official" });

  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: countries.alpha2ToAlpha3(key),
    };
  });

  return (
    <FormControl
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      error={error}
      variant={variant}
    >
      <Tooltip title={tooltip ? tooltip : ""} arrow>
        <InputLabel>{label}</InputLabel>
      </Tooltip>

      <MuiSelect label={label} name={name} value={value} onChange={onChange}>
        {!!countryArr?.length &&
          countryArr.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
      </MuiSelect>

      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
