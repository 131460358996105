export const color = {
  rgbAlpha,
  rgb,
};

function rgbAlpha(a = 0.2) {
  var r = () => (Math.random() * 256) >> 0;
  var color = `rgb(${r()}, ${r()}, ${r()}, ${a})`;
  return color;
}

function rgb() {
  var r = () => (Math.random() * 256) >> 0;
  var color = `${r()}, ${r()}, ${r()}`;
  return color;
}
