import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export default function ConfirmDialog(props) {
  const { title, children, open, setOpen, onConfirm } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {children}
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          Yes
        </Button>
        <Button variant="contained" onClick={() => setOpen(false)}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
