const baseUrl = `/api/aims`;

export const aimService = {
  post,
  getAll,
  update,
  remove,
  getByName,
  getFeatures,
};

function getAll() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(baseUrl, requestOptions).then(handleResponse);
}

function getByName(name) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(`${baseUrl}/${name}`, requestOptions).then(handleResponse);
}

function post(body) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}/`, requestOptions).then(handleResponse);
}

function update(id, params) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };

  return fetch(`${baseUrl}/${id}`, requestOptions).then(handleResponse);
}

function remove(id, params) {
  const requestOptions = {
    method: "DELETE",
    body: JSON.stringify(params),
  };
  return fetch(`${baseUrl}/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

/**
 * Gets the features of the model.
 * @param {*} name - name of the model
 * @returns The features that were used to train the model.
 */
function getFeatures(name) {
  const get = async () => {
    let features = {};

    const model = await getByName(name);
    model[0].features.map((e) => {
      features = { ...features, [e.name]: Object.assign({}, e) };
      return features;
    });

    return features;
  };

  return get();
}
