export const mc2010 = {
  fctm,
  fctmMin,
  fctmMax,
  eci,
};

/**
 * Calculates the mean value of tensile strength.
 * @param  {Number} fck The characteristic compressive strength in MPa
 * @return {Number}     The mean value of tensile strength in MPa
 */
function fctm(fck) {
  if (fck <= 50.0) return 0.3 * fck ** (2.0 / 3.0);
  else {
    const df = 8.0;
    return 2.12 * Math.log(1.0 + 0.1 * df);
  }
}

function fctmMin(fck) {
  return 0.7 * fctm(fck);
}

function fctmMax(fck) {
  return 1.3 * fctm(fck);
}

function eci(fcm, aggregate) {
  let alpha = 0;
  let ec0 = 0;

  if (aggregate === "basalt") {
    alpha = 1.2;
    ec0 = 25800;
  } else if (aggregate === "limestone") {
    alpha = 0.9;
    ec0 = 19400;
  } else if (aggregate === "sandstone") {
    alpha = 0.7;
    ec0 = 15100;
  } else {
    alpha = 1.0;
    ec0 = 21500;
  }

  return ec0 * alpha * (fcm / 10) ** (1.0 / 3.0);
}
