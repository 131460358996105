import { mc2010 } from "./mc2010";

export const checks = {
  concrete,
  steel,
};

const severity = {
  error: "error",
  warning: "warning",
};

function concrete(data) {
  const precision = 3;
  const factor = 0.25;
  let checks = [];

  const fc = +data.fc.value;
  const fck = fc - 8.0;
  if (fck < 15.0 && data.fc.shape === "cube") {
    checks.push({
      severity: severity.warning,
      message:
        "Concrete mean compressive strenght must be greater or equal to 23.0 MPa",
    });
  }
  if (fck < 12.0 && data.fc.shape === "cylinder") {
    checks.push({
      severity: severity.error,
      message:
        "Concrete mean compressive strenght must be greater or equal to 20.0 MPa",
    });
  }

  //--------------------------------------------------------------------------
  // Mean concrete tensile strength
  //--------------------------------------------------------------------------
  const fctm = mc2010.fctm(fck);
  const ft = +data.ft.value;
  let min = (1 - factor) * fctm;
  let max = (1 + factor) * fctm;
  if (ft > 0 && (min > ft || ft > max)) {
    checks.push({
      severity: severity.warning,
      message:
        "Concrete mean tensile strength value is not usual for this concrete class. Expected value is [" +
        min.toFixed(precision) +
        " - " +
        max.toFixed(precision) +
        "] MPa. Please make sure the data is correct.",
    });
  }

  //--------------------------------------------------------------------------
  // Concrete modulus of elasticity check
  //--------------------------------------------------------------------------
  const ec = +data.ec.value;
  // Finds the maximum aggregate type
  let maxAgg = 0;
  let aggregate = "";
  data.mix.aggregates.map((e, index) => {
    if (+e.quantity > maxAgg) {
      maxAgg = +e.quantity;
      aggregate = e.material;
    }
    return index;
  });

  const eci = mc2010.eci(fc, aggregate);

  min = (1 - factor) * eci;
  max = (1 + factor) * eci;
  if (ec > 0 && (min > ec || ec > max)) {
    checks.push({
      severity: severity.warning,
      message:
        "The concrete modulus of elasticity value is not usual for this concrete class. Expected value is [" +
        min.toFixed(precision) +
        "-" +
        max.toFixed(precision) +
        "] MPa. Please make sure the data is correct.",
    });
  }

  return checks;
}

function steel(data) {
  let checks = [];
  return checks;
}
