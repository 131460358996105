import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { FormikProvider, FieldArray, getIn } from "formik";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { SiCircle } from "react-icons/si";
// App
import { Input, Select } from "../../mui";
import * as options from "../../helpers/options";
import { frcService } from "../../services";
import Section from "./Section";
import useAlert from "../../hooks/useAlert";

const MixForm = ({ formik }) => {
  const { Alert, setAlert } = useAlert({
    open: false,
    type: "error",
    message: "",
  });

  const renderAgg = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body" align="left" gutterBottom>
              Aggregates
            </Typography>
          </Grid>

          <FieldArray name="mix.aggregates">
            {({ insert, remove, push }) => (
              <>
                {formik.values.mix.aggregates.length > 0 &&
                  formik.values.mix.aggregates.map((data, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={6} sm={3}>
                        <Select
                          name={`mix.aggregates.${index}.type`}
                          label="Type"
                          tooltip="Aggregate type."
                          options={options.aggType}
                          value={data.type}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Select
                          name={`mix.aggregates.${index}.material`}
                          label="Type"
                          tooltip="Aggregate material."
                          options={options.aggMat}
                          value={data.material}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={5} sm={2}>
                        <Input
                          number
                          name={`mix.aggregates.${index}.quantity`}
                          label="Quantity"
                          tooltip="Aggregate quantity."
                          value={data.quantity}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              `mix.aggregates.${index}.quantity`
                            ) &&
                              getIn(
                                formik.errors,
                                `mix.aggregates.${index}.quantity`
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              `mix.aggregates.${index}.quantity`
                            ) &&
                            getIn(
                              formik.errors,
                              `mix.aggregates.${index}.quantity`
                            )
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={4} sm={2}>
                        <Input
                          name={`mix.aggregates.${index}.size`}
                          label="Size"
                          tooltip="Aggregate maximum size or range in mm, e.g. 5, 5-12"
                          value={data.size}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              `mix.aggregates.${index}.size`
                            ) &&
                              getIn(
                                formik.errors,
                                `mix.aggregates.${index}.size`
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              `mix.aggregates.${index}.size`
                            ) &&
                            getIn(formik.errors, `mix.aggregates.${index}.size`)
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={3} sm={2}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index >= 4) {
                              setAlert({
                                open: true,
                                type: "warning",
                                message: "You can add up to 5 aggregates.",
                              });
                              return;
                            }

                            push(frcService.newAgg());
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index === 0) return;
                            remove(index);
                          }}
                        >
                          <RemoveCircleIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
              </>
            )}
          </FieldArray>
        </Grid>
      </>
    );
  };

  const renderAdditions = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body" align="left" gutterBottom>
              Additions
            </Typography>
          </Grid>
          <FieldArray name="mix.additions">
            {({ insert, remove, push }) => (
              <>
                {formik.values.mix.additions.length > 0 &&
                  formik.values.mix.additions.map((data, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={6} sm={3}>
                        <Select
                          name={`mix.additions.${index}.type`}
                          label="Type"
                          tooltip="Additiions type."
                          options={options.additionsType}
                          value={data.type}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Input
                          name={`mix.additions.${index}.brand`}
                          label="Brand"
                          tooltip="Additions brand."
                          value={data.brand}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Input
                          number
                          name={`mix.additions.${index}.quantity`}
                          label="Quantity"
                          tooltip="Additions quantity."
                          value={data.quantity}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              `mix.additions.${index}.quantity`
                            ) &&
                              getIn(
                                formik.errors,
                                `mix.additions.${index}.quantity`
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              `mix.additions.${index}.quantity`
                            ) &&
                            getIn(
                              formik.errors,
                              `mix.additions.${index}.quantity`
                            )
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index >= 2) {
                              setAlert({
                                open: true,
                                type: "warning",
                                message: "You can add up to 3 additions.",
                              });
                              return;
                            }
                            push(frcService.newAdditions());
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index === 0) return;
                            remove(index);
                          }}
                        >
                          <RemoveCircleIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
              </>
            )}
          </FieldArray>
        </Grid>
      </>
    );
  };

  const renderAdditives = () => {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body" align="left" gutterBottom>
              Additives
            </Typography>
          </Grid>
          <FieldArray name="mix.additives">
            {({ insert, remove, push }) => (
              <>
                {formik.values.mix.additives.length > 0 &&
                  formik.values.mix.additives.map((data, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={6} sm={3}>
                        <Select
                          name={`mix.additives.${index}.type`}
                          label="Type"
                          tooltip="Additive type."
                          options={options.additivesType}
                          value={data.type}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Input
                          name={`mix.additives.${index}.brand`}
                          label="Brand"
                          tooltip="Additive brand."
                          value={data.brand}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Input
                          number
                          name={`mix.additives.${index}.quantity`}
                          label="Quantity"
                          tooltip="Additives quantity."
                          value={data.quantity}
                          error={Boolean(
                            getIn(
                              formik.touched,
                              `mix.additives.${index}.quantity`
                            ) &&
                              getIn(
                                formik.errors,
                                `mix.additives.${index}.quantity`
                              )
                          )}
                          helperText={
                            getIn(
                              formik.touched,
                              `mix.additives.${index}.quantity`
                            ) &&
                            getIn(
                              formik.errors,
                              `mix.additives.${index}.quantity`
                            )
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index >= 2) {
                              setAlert({
                                open: true,
                                type: "warning",
                                message: "You can add up to 3 additives.",
                              });
                              return;
                            }
                            push(frcService.newAdditives());
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index === 0) return;
                            remove(index);
                          }}
                        >
                          <RemoveCircleIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
              </>
            )}
          </FieldArray>
        </Grid>
      </>
    );
  };

  return (
    <Section title="Mix Proportions" icon={<SiCircle />}>
      <FormikProvider value={formik}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={2}>
            <Select
              name={`mix.unit`}
              label="Unit"
              tooltip="Unit of the materials."
              options={options.unitList}
              value={formik.values.mix.unit}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <Input
              number
              name={`mix.water`}
              label="Water"
              tooltip="Water quantity."
              value={formik.values.mix.water}
              error={Boolean(
                getIn(formik.touched, "mix.water") &&
                  getIn(formik.errors, "mix.water")
              )}
              helperText={
                getIn(formik.touched, "mix.water") &&
                getIn(formik.errors, "mix.water")
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <Input
              number
              name="mix.cement.value"
              label="Cement"
              tooltip="Cement quantity."
              value={formik.values.mix.cement.value}
              error={Boolean(
                getIn(formik.touched, "mix.cement.value") &&
                  getIn(formik.errors, "mix.cement.value")
              )}
              helperText={
                getIn(formik.touched, "mix.cement.value") &&
                getIn(formik.errors, "mix.cement.value")
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <Input
              name="mix.cement.type"
              label="Cement Type"
              tooltip="Cement type."
              value={formik.values.mix.cement.type}
              error={Boolean(
                getIn(formik.touched, "mix.cement.type") &&
                  getIn(formik.errors, "mix.cement.type")
              )}
              helperText={
                getIn(formik.touched, "mix.cement.type") &&
                getIn(formik.errors, "mix.cement.type")
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <Input
              number
              name="mix.wc"
              label="WC"
              tooltip="Water/cement ratio."
              value={formik.values.mix.wc}
              error={Boolean(
                getIn(formik.touched, "mix.wc") &&
                  getIn(formik.errors, "mix.wc")
              )}
              helperText={
                getIn(formik.touched, "mix.wc") &&
                getIn(formik.errors, "mix.wc")
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={6} sm={2}>
            <Input
              required
              name="mix.cf"
              label="C"
              sub="f"
              tooltip="Fibre content."
              value={formik.values.mix.cf}
              error={Boolean(
                getIn(formik.touched, "mix.cf") &&
                  getIn(formik.errors, "mix.cf")
              )}
              helperText={
                getIn(formik.touched, "mix.cf") &&
                getIn(formik.errors, "mix.cf")
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            {renderAgg()}
          </Grid>

          <Grid item xs={12}>
            {renderAdditions()}
          </Grid>

          <Grid item xs={12}>
            {renderAdditives()}
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </FormikProvider>
      <Alert duration={2000} />
    </Section>
  );
};

export default MixForm;
