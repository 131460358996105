// import axios from "axios";
import { checks } from "../helpers";
const baseUrl = `/api/frcs`;

export const frcService = {
  reset,
  post,
  getAll,
  getByUser,
  getById,
  update,
  remove,
  check,
  newAgg,
  newAdditions,
  newAdditives,
  newFRi,
  getValues,
};

function newAgg() {
  return { type: "natural", material: "basalt", quantity: "", size: "" };
}

function newAdditions() {
  return { type: "fly ash", brand: "", quantity: "" };
}

function newAdditives() {
  return { type: "plasticizers", brand: "", quantity: "" };
}

function newFRi() {
  return { disp: "0.5 mm", value: "", measure: "cv", mvalue: "" };
}

function testing() {
  return {
    doi: "",
    reference: "my reference",
    standard: "en14651",
    specimen: "SP1",
    ns: "2",
    fRi: [{ disp: "0.5 mm", value: "4.6", measure: "cv", mvalue: "" }],
    cname: "",
    age: "",
    mix: {
      unit: "kg/m3",
      water: "",
      cement: {
        value: "",
        type: "",
      },
      wc: "",
      cf: "40",
      additions: [newAdditions()],
      additives: [newAdditives()],
      aggregates: [
        { type: "natural", material: "basalt", quantity: "100", size: "" },
        { type: "natural", material: "quartzite", quantity: "1000", size: "" },
      ],
    },
    fc: {
      value: "10",
      shape: "cylinder",
      age: "28",
      ns: "",
      standard: "CEN - European Committee for Standardization",
      stdRef: "",
      measure: "cv",
      mvalue: "",
    },
    ft: {
      value: "0.1",
      age: "",
      ns: "",
      standard: "CEN - European Committee for Standardization",
      stdRef: "",
      measure: "cv",
      mvalue: "",
    },
    ec: {
      value: "1000",
      age: "",
      ns: "",
      standard: "CEN - European Committee for Standardization",
      stdRef: "",
      measure: "cv",
      mvalue: "",
    },
    fibre: {
      material: "steel",
      geometry: "flat",
      length: "80",
      diameter: "0.8",
      young: "",
      fu: "",
    },
    comments: [],
  };
}

// Create an empty FRC object.
function reset() {
  //return testing;
  return {
    doi: "",
    reference: "",
    standard: "en14651",
    specimen: "",
    ns: "",
    fRi: [newFRi()],
    cname: "",
    mix: {
      unit: "kg/m3",
      water: "",
      cement: {
        value: "",
        type: "",
      },
      wc: "",
      cf: "",
      additions: [newAdditions()],
      additives: [newAdditives()],
      aggregates: [newAgg()],
    },
    fc: {
      value: "",
      shape: "cylinder",
      age: "",
      ns: "",
      standard: "CEN - European Committee for Standardization",
      stdRef: "",
      measure: "cv",
      mvalue: "",
    },
    ft: {
      value: "",
      ns: "",
      standard: "CEN - European Committee for Standardization",
      stdRef: "",
      measure: "cv",
      mvalue: "",
    },
    ec: {
      value: "",
      ns: "",
      standard: "CEN - European Committee for Standardization",
      stdRef: "",
      measure: "cv",
      mvalue: "",
    },
    fibre: {
      material: "steel",
      geometry: "flat",
      length: "",
      diameter: "",
      young: "",
      fu: "",
    },
    comments: [],
  };
}

function getAll() {
  return get(baseUrl);
}

function getByUser(user) {
  return get(`${baseUrl}/${user}`);
}

function getById(id) {
  return get(`${baseUrl}/id/${id}`);
}

function post(body) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify(body),
  };
  return fetch(`${baseUrl}/add`, requestOptions).then(handleResponse);
}

// Helpers
function get(url) {
  // await axios
  //   .get(`${baseUrl}/`)
  //   .then((res) => {
  //     console.log(res.data);
  //     return res.data;
  //   })
  //   .catch((err) => console.log(err));
  const requestOptions = {
    method: "GET",
    // headers: authHeader(url)
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function update(id, params) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  };

  return fetch(`${baseUrl}/${id}`, requestOptions).then(handleResponse);
}

function remove(id, params) {
  const requestOptions = {
    method: "DELETE",
    body: JSON.stringify(params),
  };
  return fetch(`${baseUrl}/${id}`, requestOptions).then(handleResponse);
}

function getValues(field) {
  const data = get(`${baseUrl}/values/${field}`);
  return data;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function check(data) {
  const concrete = checks.concrete(data);
  const steel = checks.steel(data);
  return [...concrete, ...steel];
}
