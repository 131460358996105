import React from "react";
import { FormikProvider, FieldArray, getIn } from "formik";
import { Grid, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { RiLineChartLine } from "react-icons/ri";

// App
import { Input, Select } from "../../mui";
import * as options from "../../helpers/options";
import { frcService } from "../../services";
import Section from "./Section";

const FriForm = ({ formik }) => {
  // CMOD or TCOD
  const [disp, setDispOptions] = React.useState(options.cmodList);

  React.useEffect(() => {
    formik.values.standard === "en14651"
      ? setDispOptions(options.cmodList)
      : setDispOptions(options.tcodList);
  }, [formik.values.standard]);

  const getValue = (opt) => {
    const item = disp.find((o) => o.id === opt);
    if (item) {
      return item.id;
    } else {
      return disp[0].id;
    }
  };

  const renderFri = () => {
    return (
      <>
        <Grid container spacing={3}>
          <FieldArray name="fRi">
            {({ insert, remove, push }) => (
              <>
                {formik.values.fRi.length > 0 &&
                  formik.values.fRi.map((data, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={6} sm={2}>
                        <Select
                          required
                          name={`fRi.${index}.disp`}
                          label={
                            formik.values.standard === "en14651"
                              ? "CMOD"
                              : "TCOD"
                          }
                          tooltip={
                            formik.values.standard === "en14651"
                              ? "Crack mouth opening displacement in mm."
                              : "Total Circumferential Opening Displacement in mm."
                          }
                          options={disp}
                          value={getValue(data.disp)}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Input
                          required
                          number
                          name={`fRi.${index}.value`}
                          label="fRi"
                          unit="MPa"
                          tooltip={
                            formik.values.standard === "en14651"
                              ? "Residual flexural tensile strength corresponding to CMOD = " +
                                data.disp
                              : "Residual flexural tensile strength corresponding to TCOD = " +
                                data.disp
                          }
                          value={data.value}
                          error={Boolean(
                            getIn(formik.touched, `fRi.${index}.value`) &&
                              getIn(formik.errors, `fRi.${index}.value`)
                          )}
                          helperText={
                            getIn(formik.touched, `fRi.${index}.value`) &&
                            getIn(formik.errors, `fRi.${index}.value`)
                          }
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <Select
                          name={`fRi.${index}.measure`}
                          label="Dispersion"
                          options={options.measureLists}
                          value={data.measure}
                          tooltip="Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Input
                          number
                          name={`fRi.${index}.mvalue`}
                          label="Value"
                          value={data.mvalue}
                          unit={data.measure === "sd" ? "MPa" : "%"}
                          tooltip="Measure of dispersion value."
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index === disp.length - 1) return;
                            push(frcService.newFRi());
                          }}
                        >
                          <AddCircleIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => {
                            if (index === 0) return;
                            remove(index);
                          }}
                        >
                          <RemoveCircleIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </React.Fragment>
                  ))}
              </>
            )}
          </FieldArray>
        </Grid>
      </>
    );
  };

  return (
    <Section
      title="Residual Flexural Tensile Strength"
      icon={<RiLineChartLine />}
    >
      <FormikProvider value={formik}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {renderFri()}
          </Grid>

          <Grid item xs={12}></Grid>
        </Grid>
      </FormikProvider>
    </Section>
  );
};

export default FriForm;
