import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { BiBone } from "react-icons/bi";

// App
import * as options from "../../helpers/options";
import { Input, Select } from "../../mui";
import Section from "./Section";

const FtForm = ({ formik }) => {
  const [unitFtDisp, setUnitFtDisp] = React.useState("%");

  const getLabel = (label) => {
    const id = options.stdOrgList.findIndex((e) => e.label === label);
    return options.stdOrgList[id];
  };

  return (
    <Section title="Tensile Strength" icon={<BiBone />}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            value={getLabel(formik.values.ft.standard)}
            options={options.stdOrgList}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(e, value) => {
              if (value != null) {
                formik.setFieldValue("ft.standard", value.label);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={"ft.standard"}
                label="Standard"
                variant="standard"
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            number
            name="ft.value"
            label="f"
            sub="ctm"
            unit="MPa"
            tooltip="Mean tensile strength in MPa (Experimental result only, do not add calculated values)."
            value={formik.values.ft.value}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            name="ft.age"
            label="Age"
            tooltip="Tensile strength age in days."
            value={formik.values.ft.age}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            name="ft.ns"
            label="Nr. Specimens"
            tooltip="Number of specimens used in the tensile strength test."
            value={formik.values.ft.ns}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Select
            name="ft.measure"
            label="Dispersion"
            options={options.measureLists}
            tooltip="Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
            value={formik.values.ft.measure}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              setUnitFtDisp(e.target.value === "cv" ? "%" : "MPa");
            }}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            number
            name="ft.mvalue"
            label="Value"
            unit={unitFtDisp}
            tooltip="Measure of dispersion value."
            value={formik.values.ft.mvalue}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </Section>
  );
};

export default FtForm;
