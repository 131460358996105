import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";

import { accountService, alertService } from "../../services";
import { Select, SelectCountry } from "../../mui";

const titleOptions = [
  { id: "Mr", title: "Mr" },
  { id: "Mrs", title: "Mrs" },
  { id: "Dr", title: "Dr" },
  { id: "Prof", title: "Prof" },
];

const userData = {
  title: "",
  firstName: "",
  lastName: "",
  affiliation: "",
  country: "",
  position: "",
  email: "",
  password: "",
  confirmPassword: "",
  acceptTerms: true,
};

function Register() {
  const navigate = useNavigate();
  // const params = useParams();
  // const { id } = params.id;
  // const isAddMode = !id;

  const [initialValues, setInitialValues] = useState(userData);
  const [addMode, setAddMode] = useState(true);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    affiliation: Yup.string().required("Affiliation is required"),
    position: Yup.string().required("Position is required"),
    country: Yup.string().required("Position is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .concat(addMode ? Yup.string().required("Password is required") : null)
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .when("password", (password, schema) => {
        if (password || addMode)
          return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Passwords must match"),

    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required"
    ),
  });

  const onSubmit = (fields, { setStatus, setSubmitting }) => {
    setStatus();
    if (addMode) {
      accountService
        .register(fields)
        .then(() => {
          alertService.success(
            "Registration successful, please check your email for verification instructions",
            { keepAfterRouteChange: true }
          );
          navigate("/account/login");
        })
        .catch((error) => {
          setSubmitting(false);
          alertService.error(error);
        });
    } else {
      const user = accountService.userValue;
      accountService
        .update(user.id, fields)
        .then(() => {
          alertService.success("User profile updated", {
            keepAfterRouteChange: true,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          alertService.error(error);
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,

    validationSchema: validationSchema,

    onSubmit: onSubmit,
  });

  React.useEffect(() => {
    const user = accountService.userValue;

    if (user) {
      setInitialValues({
        title: user.title,
        firstName: user.firstName,
        lastName: user.lastName,
        affiliation: user.affiliation,
        country: user.country,
        position: user.position,
        email: user.email,
        password: "",
        confirmPassword: "",
        acceptTerms: true,
      });

      setAddMode(false);
    }
  }, []);

  const renderForm = () => {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} sx={{ width: 600, m: "20px auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {!addMode ? "Your Profile" : "Sign Up"}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Select
                fullWidth
                name="title"
                label="Title"
                variant="outlined"
                value={formik.values.title}
                onChange={formik.handleChange}
                options={titleOptions}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              >
                {titleOptions.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={10}></Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                label="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                label="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="affiliation"
                name="affiliation"
                label="Affiliation"
                value={formik.values.affiliation}
                onChange={formik.handleChange}
                error={
                  formik.touched.affiliation &&
                  Boolean(formik.errors.affiliation)
                }
                helperText={
                  formik.touched.affiliation && formik.errors.affiliation
                }
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                id="position"
                name="position"
                label="Position"
                value={formik.values.position}
                onChange={formik.handleChange}
                error={
                  formik.touched.position && Boolean(formik.errors.position)
                }
                helperText={formik.touched.position && formik.errors.position}
              />
            </Grid>
            <Grid item xs={5}>
              <SelectCountry
                variant="outlined"
                name="country"
                label="Country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={addMode ? false : true}
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            {!addMode && (
              <Grid item xs={12}>
                <Typography variant="h6">Change Password</Typography>
                <Typography variant="body">
                  Leave blank to keep the same password
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmPassword &&
                  Boolean(formik.errors.confirmPassword)
                }
                helperText={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={8}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </form>
    );
  };

  return <div>{renderForm()}</div>;
}

export default Register;
