import React from "react";
import {
  Grid,
  Typography,
  Stack,
  Container,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Slider,
  FormLabel,
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { statsService } from "../services";
import { statsList } from "../helpers";
import { Select } from "../mui";
import { color } from "../helpers";
import { Box } from "@mui/system";

ChartJS.register(ArcElement, Tooltip, Legend);

const initialOptions = {
  plugins: {
    legend: { display: true, position: "right" },
    tooltips: {
      enabled: true,
    },
  },
};

const initialData = {
  labels: [],
  datasets: [
    {
      label: "",
      data: [],
      borderWidth: 1,
    },
  ],
};

const legPosList = [
  { id: "top", title: "Top" },
  { id: "bottom", title: "Bottom" },
  { id: "left", title: "Left" },
  { id: "right", title: "Right" },
];

const StatsTable = ({ rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell>Stats</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="right">Unit</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.value}</TableCell>
              <TableCell align="right">{row.unit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Statistics = () => {
  // Total number of records in the database
  const [total, setTotal] = React.useState(0);
  // Selected feature
  const [params, setParams] = React.useState("fc.value");
  const [legpos, setLegpos] = React.useState("right");
  // Selected feature values
  const [values, setValues] = React.useState([]);
  // Selected feature statistics
  const [stats, setStats] = React.useState({});
  // Rows of the statistic table
  const [rows, setRows] = React.useState([]);
  // Chart data
  const [data, setData] = React.useState(initialData);
  // Chart options
  const [options, setOptions] = React.useState(initialOptions);
  // Chart intervals
  const [intervals, setIntervals] = React.useState(5);

  const getTotalRecords = async () => {
    const total = await statsService.getTotalData();
    setTotal(total);
  };

  const calcStats = async () => {
    const res = await statsService.getStats(params);
    setValues(res.values);
    setStats(res.stats);

    // Create table row object.
    const newRows = [
      { name: "Total", value: res.stats.total, unit: "N/A" },
      { name: "Mean", value: res.stats.mean.toFixed(2), unit: "MPa" },
      { name: "SD", value: res.stats.sd.toFixed(2), unit: "MPa" },
      { name: "Min", value: res.stats.min.toFixed(2), unit: "MPa" },
      { name: "25%", value: res.stats.q25.toFixed(2), unit: "MPa" },
      { name: "50%", value: res.stats.q50.toFixed(2), unit: "MPa" },
      { name: "75%", value: res.stats.q75.toFixed(2), unit: "MPa" },
      { name: "Max", value: res.stats.max.toFixed(2), unit: "MPa" },
    ];
    const { counts, labels } = statsService.getPieData(
      res.values,
      intervals - 1
    );
    setRows(newRows);

    // Generates random chart colours
    let bg = [];
    let bgb = [];
    for (let index = 0; index < labels.length; index++) {
      const rgb = color.rgb();
      bg.push(`rgb(${rgb}, 0.2)`);
      bg.push(`rgb(${rgb}, 1.0)`);
    }

    // Create data object
    const newData = {
      labels: labels,
      datasets: [
        {
          label: "",
          data: counts,
          backgroundColor: bg,
          borderColor: bgb,
          borderWidth: 1,
        },
      ],
    };
    setData(newData);

    return res;
  };

  React.useEffect(() => {
    getTotalRecords();
    calcStats();
  }, [params, intervals]);

  const handleChange = (event) => {
    if (event.target.name === "legpos") {
      setLegpos(event.target.value);
      const newOptions = {
        plugins: {
          legend: { display: true, position: event.target.value },
          tooltips: {
            enabled: true,
          },
        },
      };
      setOptions(newOptions);
    } else {
      setParams(event.target.value);
    }
  };

  const handleSlider = (event) => {
    setIntervals(event.target.value);
  };

  return (
    <Container>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sm={12}>
          <Typography component="h6" variant="h6" align="center">
            Total number of records: {total}
          </Typography>
        </Grid>

        <Grid item sm={5}>
          <Stack spacing={3}>
            <Select
              name="params"
              label="Parameters"
              tooltip="Select a parameter."
              options={statsList}
              value={params}
              onChange={handleChange}
            />

            <Select
              name="legpos"
              label="Legend position"
              tooltip="Select the legend position."
              options={legPosList}
              value={legpos}
              onChange={handleChange}
            />
            <Box>
              <FormLabel>Number of intervals</FormLabel>
              <Slider
                aria-label="Temperature"
                value={intervals}
                valueLabelDisplay="auto"
                step={1}
                marks
                min={1}
                max={10}
                onChange={handleSlider}
              />
            </Box>
          </Stack>
          <StatsTable rows={rows} />
        </Grid>

        <Grid item sm={7}>
          <Stack alignItems="center" spacing={2}>
            <Doughnut data={data} options={options} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Statistics;
