const stdOrgList = [
  {
    country: "Brazil",
    label: "ABNT - Associação Brasileira de Normas Técnicas",
  },
  {
    country: "Austria",
    label: "ASI - Austrian Standards International",
  },
  {
    country: "Bosnia and Herzegovina",
    label:
      "BASMP - Institute for Standards, Metrology and Intellectual Property of Bosnia and Herzegovina",
  },
  {
    country: "Bulgaria",
    label: "BDS - Bulgarian Institute for Standardization",
  },
  {
    country: "Belgium",
    label:
      "BEC / CEB - The Belgian Electrotechnical Committee - Belgisch Elektrotechnisch Comité - Comité Electrotechnique Belge",
  },
  {
    country: "Belarus",
    label:
      "BELST - Committee for Standardization, Metrology and Certification of Belarus",
  },
  {
    country: "Barbados",
    label: "BNSI - Barbados National Standards Institution",
  },
  {
    country: "Botswan",
    label: "BOBS - Botswana Bureau of Standards",
  },
  {
    country: "Bahrain",
    label: "BSMD - Bahrain Standards and Metrology Directorate",
  },
  {
    country: "Bangladesh",
    label:
      "BSTI - Bangladesh Standards and Bangladesh Standards and Testing Institution",
  },
  {
    country: "Brunei Darussalam",
    label:
      "CPRU - Construction Planning and Research Unit, Ministry of Development",
  },
  {
    country: "China ",
    label: "CSSN - China Standards Information Center",
  },
  {
    country: "Denmark",
    label: "DS - Dansk Standard",
  },
  {
    country: "Bolivia",
    label: "IBNORCA - Instituto Boliviano de Normalización y Calidad",
  },
  {
    country: "Colombia",
    label: "ICONTEC - Instituto Colombiano de Normas Tecnicas y Certificación",
  },
  {
    country: "Ecuador",
    label: "INEN - Instituto Ecuatoriano de Normalización",
  },
  {
    country: "Costa Rica",
    label: "INTECO - Instituto de Normas Técnicas de Costa Rica",
  },
  {
    country: "Argentina",
    label: "IRAM - Instituto Argentino de Normalización",
  },
  {
    country: "Australia",
    label: "SA - Standards Australia",
  },
  {
    country: "Armenia",
    label: "SARM - National Institute of Standards and Quality",
  },
  {
    country: "Americas",
    label: "AAQG - America's Aerospace Quality Group",
  },
  {
    label: "ACCSQ - ASEAN Consultative Committee for Standards and Quality",
    country: "Asia Pacific",
  },
  {
    country: "United States of America",
    label: "ACI - American Concrete Institute",
  },
  {
    country: "France",
    label: "AFNOR - Association française de normalisation",
  },
  {
    country: "Europe",
    label: "AIDMO - Arab Industrial Development and Mining Organization",
  },
  {
    country: "Americas",
    label: "AMN - MERCOSUR Standardization Association",
  },
  {
    country: "Afghanistan",
    label: "ANSA - Afghan National Standard Authority",
  },
  {
    country: "United States of America",
    label: "ANSI - American National Standards Institute",
  },
  {
    country: "Africa",
    label: "ARSO - African Organization for Standardization",
  },
  {
    country: "Romania",
    label: "ASRO - Asociatia de Standardizare din România",
  },
  {
    country: "United States of America",
    label: "ASTM - American Society for Testing and Materials",
  },
  {
    country: "India",
    label: "BIS - Bureau of Indian Standards",
  },
  {
    country: "Philippines",
    label: "BPS - Bureau of Product Standards",
  },
  {
    country: "United Kingdom",
    label: "BSI - British Standards Institution aka BSI Group",
  },
  {
    country: "Jamaica",
    label: "BSJ - Bureau of Standards, Jamaica",
  },
  {
    country: "Taiwan (Republic of China)",
    label: "BSMI - The Bureau of Standards, Metrology and Inspection",
  },
  {
    country: "Indonesia",
    label: "BSN - Badan Standardisasi Nasional",
  },
  {
    country: "Europe",
    label: "CEN - European Committee for Standardization",
  },
  {
    country: "Europe",
    label: "CENELEC - European Committee for Electrotechnical Standardization",
  },
  {
    country: "Guatemala",
    label: "COGUANOR - Comisión Guatemalteca de Normas",
  },
  {
    country: "El Salvador",
    label: "CONACYT - Consejo Nacional de Ciencia y Tecnología",
  },
  {
    country: "Panama",
    label: "COPANIT - Comisión Panameña de Normas Industriales y Técnicas",
  },
  {
    country: "Americas",
    label: "COPANT - Pan American Standards Commission",
  },
  {
    country: "Americas",
    label: "CROSQ - CARICOM Regional Organization for Standards and Quality",
  },
  {
    country: "Canada",
    label: "CSA - Canadian Standards Association",
  },
  {
    country: "Czech Republic",
    label: "CSNI - Czech Standards Institute",
  },
  {
    country: "Mexico",
    label: "DGN - Dirección General de Normas",
  },
  {
    country: "Oman",
    label: "DGSM - Directorate General for Specifications and Measurements",
  },
  {
    country: "Germany ",
    label: "DIN - Deutsches Institut für Normung",
  },
  {
    country: "Germany ",
    label:
      "DKE - Deutsche Kommission für Elektrotechnik Elektronik Informationstechnik in DIN und VDE",
  },
  {
    country: "Malaysia",
    label: "DSM - Department of Standards Malaysia",
  },
  {
    country: "Ukraine",
    label:
      "DSSU - State Committee for Technical Regulation and Consumer Policy of Ukraine",
  },
  {
    country: "United Kingdom",
    label: "DStan - UK Defence Standardization",
  },
  {
    country: "Nicaragua",
    label: "DTNM - Dirección de Tecnología, Normalización y Metrología",
  },
  {
    country: "Croatia",
    label: "DZNM - State Office for Standardization and Metrology",
  },
  {
    country: "Europe",
    label:
      "EASC - Euro-Asian Council for Standardization, Metrology and Certification",
  },
  {
    country: "Europe",
    label: "ECSS - European Cooperation for Space Standardization",
  },
  {
    country: "Europe",
    label: "EIGA - European Industrial Gases Association",
  },
  {
    country: "Greece",
    label: "ELOT - Hellenic Organization for Standardization",
  },
  {
    country: "Egypt",
    label: "EO - Egyptian Organization for Standardization and Quality Control",
  },
  {
    country: "United Arab Emirates",
    label: "ESMA - Emirates Standardization and Metrology Association",
  },
  {
    country: "Europe",
    label: "ETSI - European Telecommunications Standards Institute",
  },
  {
    country: "Europe",
    label: "EURAMET - European Association of National Metrology Institutes",
  },
  {
    country: "Estonia",
    label: "EVS - Eesti Standardikeskus",
  },
  {
    country: "Venezuela",
    label:
      "FONDONORMA - Fondo para la Normalización y Certificación de la Calidad",
  },
  {
    country: "Grenada",
    label: "GDBS - Grenada Bureau of Standards",
  },
  {
    country: "Georgia",
    label:
      "GEOSTM - Georgian National Agency for Standards, Technical Regulations and Metrology",
  },
  {
    country: "Guyana",
    label: "GNBS - Guyana National Bureau of Standards",
  },
  {
    country: "Ghana",
    label: "GSA - Ghana Standards Authority",
  },
  {
    country: "Algeria",
    label: "IANOR - Institut algérien de normalisation",
  },
  {
    country: "Europe",
    label: "IAU - International Arabic Union",
  },
  {
    country: "Peru",
    label:
      "INDECOPI - Instituto Nacional de Defensa de la Competencia y de la Protección de la Propiedad Intellectual",
  },
  {
    country: "Chile",
    label: "INN - Instituto Nacional de Normalizacion",
  },
  {
    country: "Portugal",
    label: "IPQ - Instituto Português da Qualidade",
  },
  {
    country: "Europe",
    label:
      "IRMM - Institute for Reference Materials and Measurements (European Union)",
  },
  {
    country: "Iran",
    label: "ISIRI - Institute of Standards and Industrial Research of Iran",
  },
  {
    country: "Serbia and Montenegro",
    label: "ISSM -Institution for Standardization of Serbia and Montenegro",
  },
  {
    country: "Iceland",
    label: "IST - Icelandic Council for Standardization",
  },
  {
    country: "Hong Kong",
    label: "ITCHKSAR - Innovation and Technology Commission",
  },
  {
    country: "Japan",
    label: "JISC - Japan Industrial Standards Committee",
  },
  {
    country: "Jordan",
    label: "JISM - Jordan Institution for Standards and Metrology",
  },
  {
    country: "Republic of Korea",
    label: "KATS - Korean Agency for Technology and Standards",
  },
  {
    country: "Kazakhstan",
    label:
      "KAZMEMST - Committee for Standardization, Metrology and Certification",
  },
  {
    country: "Kenya",
    label: "KEBS - Kenya Bureau of Standards",
  },
  {
    country: "Kuwait",
    label:
      "KOWSMD - Public Authority for Industry, Standards and Industrial Services Affairs",
  },
  {
    country: "Kyrgyzstan",
    label: "KYRGYZST - State Inspection for Standardization and Metrology",
  },
  {
    country: "Lebanon",
    label: "LIBNOR - Lebanese Standards Institution",
  },
  {
    country: "Lithuania",
    label: "LST - Lithuanian Standards Board",
  },
  {
    country: "Latvia",
    label: "LVS - Latvian Standard",
  },
  {
    country: "Moldova",
    label: "MOLDST - Department of Standardization and Metrology",
  },
  {
    country: "Malta",
    label: "MSA - Malta Standards Authority",
  },
  {
    country: "Mauritius",
    label: "MSB - Mauritius Standards Bureau",
  },
  {
    country: "Hungary",
    label: "MSZT - Magyar Szabványügyi Testület",
  },
  {
    country: "Belgium",
    label:
      "NBN - Bureau voor Normalisatie/Bureau de Normalisation (formerly: IBN/BIN)",
  },
  {
    country: "Cuba",
    label: "NC - Oficina Nacional de Normalización",
  },
  {
    country: "Netherlands",
    label:
      "NEN - Nederlandse Norm, maintained by the Nederlands Normalisatie Instituut (NNI)",
  },
  {
    country: "Papua New Guinea",
    label: "NISIT - National Institute of Standards and Industrial Technology",
  },
  {
    country: "United States of America",
    label: "NISO - National Information Standards Organization",
  },
  {
    country: "United States of America",
    label: "NIST - National Institute of Standards and Technology",
  },
  {
    country: "Ireland",
    label: "NSAI - National Standards Authority of Ireland",
  },
  {
    country: "Nepal",
    label: "NTA - Nepal Telecommunication Authority",
  },
  {
    country: "Asia Pacific",
    label: "PASC - Pacific Area Standards Congress",
  },
  {
    country: "Poland",
    label: "PKN - Polish Committee for Standardization",
  },
  {
    country: "Palestine",
    label: "PSI - Palestine Standards Institution",
  },
  {
    country: "Pakistan",
    label: "PSQCA - Pakistan Standards and Quality Control Authority",
  },
  {
    country: "Ethiopia",
    label: "QSAE - Quality and Standards Authority of Ethiopia",
  },
  {
    country: "Russian Federation",
    label: "Rosstandart - Federal Technical Regulation and Metrology Agency",
  },
  {
    country: "South Africa",
    label: "SABS - South African Bureau of Standards",
  },
  {
    country: "China",
    label: "SAC - Standardization Administration of China",
  },
  {
    country: "Africa",
    label:
      "SADCSTAN - Southern African Development Community (SADC) Cooperation in Standardization",
  },
  {
    country: "Syrian Arab Republic",
    label:
      "SASMO - The Syrian Arab Organization for Standardization and Metrology",
  },
  {
    country: "Saudi Arabia",
    label: "SASO - Saudi Arabian Standards Organization",
  },
  {
    country: "Seychelles",
    label: "SBS - Seychelles Bureau of Standards",
  },
  {
    country: "Canada",
    label: "SCC - Standards Council of Canada",
  },
  {
    country: "Luxembourg",
    label:
      "SEE - Service de l'Energie de l'Etat, Organisme Luxembourgeois de Normalisation",
  },
  {
    country: "Finland",
    label: "SFS - Finnish Standards Association",
  },
  {
    country: "Israel",
    label: "SII - The Standards Institution of Israel",
  },
  {
    country: "Sweden",
    label: "SIS - Swedish Standards Institute",
  },
  {
    country: "Slovenia",
    label: "SIST - Slovenian Institute for Standardization",
  },
  {
    country: "Saint Lucia",
    label: "SLBS - Saint Lucia Bureau of Standards",
  },
  {
    country: "Sri Lanka",
    label: "SLSI - Sri Lanka Standards Institution",
  },
  {
    country: "Norway",
    label: "SN - Standards Norway (Standard Norge)",
  },
  {
    country: "Morocco",
    label: "SNIMA - Service de Normalisation Industrielle Marocaine",
  },
  {
    country: "Switzerland",
    label: "SNV - Swiss Association for Standardization",
  },
  {
    country: "New Zealand",
    label: "SNZ - Standards New Zealand",
  },
  {
    country: "Somalia",
    label: "SOBS - Somali Bureau of Standards",
  },
  {
    country: "Africa",
    label: "SON - Standard Organization of Nigeria",
  },
  {
    country: "Nigeria",
    label: "SON - Standards Organisation of Nigeria",
  },
  {
    country: "Singapore",
    label: "SPRING SG - Standards, Productivity and Innovation Board",
  },
  {
    country: "Suriname",
    label: "SSB - Suriname Standards Bureau",
  },
  {
    country: "Slovakia",
    label: "SUTN - Slovak Standards Institute",
  },
  {
    country: "Tanzania",
    label: "TBS - Tanzania Bureau of Standards",
  },
  {
    country: "Vietnam",
    label: "TCVN - Directorate for Standards and Quality",
  },
  {
    country: "Thailand",
    label: "TISI - Thai Industrial Standards Institute",
  },
  {
    country: "Turkey",
    label: "TSE - Türk Standardlari Enstitüsü",
  },
  {
    country: "Trinidad and Tobago",
    label: "TTBS - Trinidad and Tobago Bureau of Standards",
  },
  {
    country: "Uganda",
    label: "UNBS - Uganda National Bureau of Standards",
  },
  {
    country: "Spain",
    label: "UNE - Asociación Española de Normalización y Certificación (AENOR)",
  },
  {
    country: "Italy",
    label: "UNI - Ente Nazionale Italiano di Unificazione",
  },
  {
    country: "Uruguay",
    label: "UNIT - Instituto Uruguayo de Normas Técnicas",
  },
  {
    country: "Europe",
    label: "WELMEC - European Cooperation in Legal Metrology",
  },
];

// Measure of dispersion types
// CV - Coefficient of variation
// SD - Standard deviation
const measureLists = [
  { id: "cv", title: "CV" },
  { id: "sd", title: "SD" },
];

// -----------------------
// Flexural standards
// -----------------------
// EN 14651. Test method for metallic concrete - Measuring the flexural tensile
// strength (limit of proportionality (LOP), residual). European Committee for
// Standardization (2005).

// [21] ASTM C1609. Standard Test Method for Flexural Performance of Fiber-Reinforced
// Concrete (Using Beam With Third-Point Loading). American Society for Testing
// and Materials (2012).

// Molins C, Aguado A, Saludes S. Double Punch Test to control the energy
// dissipation in tension of FRC (Barcelona test). Mater Struct 2009;42:415–25.
const stdList = [
  { id: "en14651", title: "EN 14651" },
  { id: "barcelona", title: "Barcelona" },
];

const unitList = [
  { id: "kg/m3", title: "kg/m3" },
  { id: "%", title: "%" },
];

const fcShapeList = [
  { id: "cylinder", title: "Cylinder" },
  { id: "cube", title: "Cube" },
];

const cmodList = [
  { id: "0.5 mm", title: "0.5 mm" },
  { id: "1.5 mm", title: "1.5 mm" },
  { id: "2.5 mm", title: "2.5 mm" },
  { id: "3.5 mm", title: "3.5 mm" },
];

const tcodList = [
  { id: "0.625 mm", title: "0.625 mm" },
  { id: "1.250 mm", title: "1.250 mm" },
  { id: "1.875 mm", title: "1.875 mm" },
  { id: "2.500 mm", title: "2.500 mm" },
  { id: "3.125 mm", title: "3.125 mm" },
  { id: "3.750 mm", title: "3.750 mm" },
  { id: "4.375 mm", title: "4.375 mm" },
];

const fibreMatList = [
  { id: "steel", title: "Steel" },
  { id: "glass", title: "Glass" },
  { id: "basalt", title: "Basalt" },
  { id: "polypropylene", title: "Polypropylene" },
];

const fibreGeoList = [
  { id: "flat", title: "Flat" },
  { id: "hooked", title: "Hooked ends" },
  { id: "straight", title: "Straight" },
  { id: "scoiled", title: "Slightly coiled" },
  { id: "other", title: "Other" },
];

const aggType = [
  { id: "natural", title: "Natural" },
  { id: "crushed", title: "Crushed" },
];

const aggMat = [
  { id: "basalt", title: "Basalt" },
  { id: "quartzite", title: "Quartzite" },
  { id: "limestone", title: "Limestone" },
  { id: "sandstone", title: "Sandstone" },
];

const additionsType = [
  { id: "fly ash", title: "Fly ash" },
  {
    id: "ground granulated blast furnace slag",
    ttile: "Ground granulated blast furnace slag (GGBFS)",
  },
  {
    id: "high reactivity Metakaolin",
    title: "High reactivity Metakaolin (HRM)",
  },
  { id: "limestone filler", title: "Limestone filler" },
  { id: "silica Fume", title: "Silica Fume" },
];

const additivesType = [
  { id: "accelerators", title: "Accelerators " },
  { id: "air entraining agents", title: "Air entraining agents" },
  { id: "bonding agents", title: "Bonding agents" },
  { id: "corrosion inhibitors", title: "Corrosion inhibitors" },
  { id: "crystalline admixtures", title: "Crystalline admixtures" },
  { id: "pigments", title: "Pigments" },
  { id: "plasticizers", title: "Plasticizers" },
  { id: "superplasticizers", title: "Superplasticizers" },
  { id: "pumping", title: "Pumping" },
  { id: "retarders", title: "Retarders" },
];

const statsList = [
  { id: "fc.value", title: "Concrete compressive strength" },
  { id: "ft.value", title: "Concrete tensile strength" },
  { id: "ec.value", title: "Concrete modulus of elasticity" },
  { id: "mix.cf", title: "Fibre content" },
  { id: "fibre.length", title: "Fibre length" },
  { id: "fibre.diameter", title: "Fibre diameter" },
];

export {
  measureLists,
  stdList,
  stdOrgList,
  unitList,
  fcShapeList,
  fibreMatList,
  fibreGeoList,
  aggType,
  aggMat,
  additionsType,
  additivesType,
  cmodList,
  tcodList,
  statsList,
};
