export const citeService = {
  toApa,
};

// Converts the crossref json data to APA citation.
// Templates: apa, vancouver
function toApa(json, format = "text", template = "apa") {
  const Cite = require("citation-js");

  const jdata = {
    type: json.type,
    title: json.title,
    DOI: json.doi,
    author: json.author,
    issued: json.published,
    "container-title": json["short-container-title"],
    volume: json.volume,
    //issue: json.issue,
    page: json.page,
  };

  // create an instance of Cite
  const cite = new Cite();

  // add the first json entry
  cite.add(jdata);

  // create a bibliography
  const formatted = cite.format("bibliography", {
    format: format,
    template: template,
  });

  return formatted;
}
