import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";

import { Section, Tools } from "../components";
import Statistics from "./Statistics";

import imgData from "../resources/database.png";
import imgFri from "../resources/frc.jpg";

const Home = () => {
  return (
    <Stack spacing={2}>
      <Section title="Welcome to AIpFRC" align="center">
        <Stack spacing={2} align="center">
          <Typography variant="body">
            The AIpFRC initiative is supported by the project FemWebAI. It aims
            to assist producers of fibre reinforced concrete (FRC), designers
            and researchers interested in the analysis and design of FRC
            structures by providing useful information based on reliable
            databases and supported by artificial intelligence (AI) techniques.
            The AIpFRC intends to be an ecosystem for several tools, including,
            but not limited to 1) a tool for mix design optimization of FRC to
            attain rheological, mechanical, durability and sustainability target
            indicators; 2) a tool for assessing relevant parameters for defining
            the constitutive laws adopted on the FEM-based numerical simulations
            and design-oriented formulations, and 3) a tool for the life cycle
            analysis.
          </Typography>
          <NavLink to="/about">
            <Button variant="contained" align="center" sx={{ width: "200px" }}>
              Read More
            </Button>
          </NavLink>
        </Stack>
      </Section>

      <Section title="Database Statistics" align="center">
        <Statistics />
      </Section>

      <Section title="Tools" align="center">
        <Grid
          container
          justifyContent="center"
          style={{ display: "gridRow", gridGap: "8px" }}
        >
          <Tools
            title="Database"
            description="A collection of reliable experimental data available for research and industry purposes."
            image={imgData}
            link="database"
          />

          <Tools
            title="fRi"
            description="A tool based on AI for prediction of the residual flexural tensile strength of FRC."
            image={imgFri}
            link="predict"
          />
        </Grid>
      </Section>
    </Stack>
  );
};

export default Home;
