import React from "react";
import { Link, Stack, Box, Typography, Grid } from "@mui/material";
import { FaCreativeCommons, FaCreativeCommonsBy } from "react-icons/fa";

function Copyright() {
  return (
    <Stack alignItems="center">
      <Stack direction="row" spacing={1}>
        <Link href="#" underline="none">
          Terms of Use |
        </Link>
        <Link href="#" underline="none">
          Citing |
        </Link>
        <Link href="mailto: contact@aipfrc.org" underline="none">
          Contact Us
        </Link>
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <FaCreativeCommons size={24} /> <FaCreativeCommonsBy size={24} />
        <Typography variant="body2" color="text.secondary">
          {"Database licensed under a "}
          <Link
            href="https://creativecommons.org/licenses/by/4.0/"
            target="_blank"
            rel="noopener"
            underline="none"
          >
            Creative Commons Attribution 4.0 International License.
          </Link>
        </Typography>
      </Stack>
    </Stack>
  );
}

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        // px: 20,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.background
            : theme.palette.background,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Copyright />
      </Grid>
    </Box>
  );
};

export default Footer;
