import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { BiCube } from "react-icons/bi";
// App
import * as options from "../../helpers/options";
import { Input, Select } from "../../mui";
import Section from "./Section";

const EcForm = ({ formik }) => {
  const [unitEcDisp, setUnitEcDisp] = React.useState("%");

  const getLabel = (label) => {
    const id = options.stdOrgList.findIndex((e) => e.label === label);
    return options.stdOrgList[id];
  };

  return (
    <Section title="Modulus of Elasticity" icon={<BiCube />}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            value={getLabel(formik.values.ec.standard)}
            options={options.stdOrgList}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            onChange={(e, value) => {
              if (value != null) {
                formik.setFieldValue("ec.standard", value.label);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name={"ec.standard"}
                label="Standard"
                variant="standard"
              />
            )}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            number
            name="ec.value"
            label="E"
            sub="c"
            unit="MPa"
            tooltip="Concrete modulus of elasticity in MPa (Experimental result only, do not add calculated values)."
            value={formik.values.ec.value}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            name="ec.age"
            label="Age"
            tooltip="Modulus of elasticity age in days."
            value={formik.values.ec.age}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            name="ec.ns"
            label="Nr. Specimens"
            tooltip="Number of specimens used in the modulus of elasticity test."
            value={formik.values.ec.ns}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Select
            name="ec.measure"
            label="Dispersion"
            options={options.measureLists}
            tooltip="Measure of dispersion. CV: Coefficient of Variation or SD: Standard Deviation"
            value={formik.values.ec.measure}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              formik.handleChange(e);
              setUnitEcDisp(e.target.value === "cv" ? "%" : "MPa");
            }}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            number
            name="ec.mvalue"
            label="Value"
            unit={unitEcDisp}
            tooltip="Measure of dispersion value."
            value={formik.values.ec.mvalue}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
    </Section>
  );
};

export default EcForm;
