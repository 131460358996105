import React from "react";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { RiFileInfoLine } from "react-icons/ri";
// App imports
import { Input, Select } from "../../mui";
import * as options from "../../helpers/options";
import { alertService, citeService } from "../../services";
import Section from "./Section";

const GeneralForm = ({ formik }) => {
  async function handleSearch() {
    // Search data using the Crossref API.
    // See https://api.crossref.org/swagger-ui/index.html
    const url = "https://api.crossref.org/works/" + formik.values.doi;

    fetch(url)
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response statusText
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        formik.setFieldValue("reference", citeService.toApa(data.message));
      })
      .catch((error) => {
        alertService.clear();
        alertService.error("DOI not found. Please add the reference manually.");
      });
  }

  return (
    <Section title="General Information" icon={<RiFileInfoLine />}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Input
            name="doi"
            label="DOI"
            tooltip="Type the DOI number of the reference and click on the search button."
            value={formik.values.doi}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.doi && formik.errors.doi}
            helperText={formik.touched.doi && formik.errors.doi}
            icon={<SearchIcon />}
            onIconClick={handleSearch}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Input
            required
            multiline
            name="reference"
            label="Reference"
            tooltip="Full reference."
            value={formik.values.reference}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.reference && formik.errors.reference}
            helperText={formik.touched.reference && formik.errors.reference}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Select
            name="standard"
            label="Standard"
            tooltip="Test standard used to obtain the residual flexural tensile strength."
            options={options.stdList}
            value={formik.values.standard}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            name="age"
            label="Age"
            tooltip="Testing date in days."
            value={formik.values.age}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Input
            name="cname"
            label="Designation"
            tooltip="Fibre reinforced concrete designation, e.g. SSFRC, SHFRC, etc"
            value={formik.values.cname}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.cname && formik.errors.cname}
            helperText={formik.touched.cname && formik.errors.cname}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <Input
            required
            name="specimen"
            label="Specimen"
            tooltip="Original name of the specimen used by the authors in the paper."
            value={formik.values.specimen}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.specimen && formik.errors.specimen}
            helperText={formik.touched.specimen && formik.errors.specimen}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <Input
            required
            number
            name="ns"
            label="No. of specimens"
            tooltip="Number of specimens used in the flexural test."
            value={formik.values.ns}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.ns && formik.errors.ns}
            helperText={formik.touched.ns && formik.errors.ns}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </Section>
  );
};

export default GeneralForm;
